main.content {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0 80px;

	// Mobile layout will be served if browser doesn't support grid
	@media (min-width: 1025px) {
		margin: 100px 0 80px;
		display: grid;
		grid-template-columns: 1fr 700px 50px 230px 1fr;
		grid-template-rows: 360px 1fr;
		grid-template-areas:
			"banner banner	banner 	banner 	banner"
			". 			content gap 		aside 	.";
	}

	@media (min-width: 1200px) {
		grid-template-columns: 1fr 740px 120px 280px 1fr;
	}

	section.banner {
		grid-area: banner;
	  height: 360px;
	  width: 100vw;
		position: relative;
		margin: 0;
    max-width: 100%;

		@media (max-width: 767px) {
			height: 180px;
		}

		picture {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bannerContent {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;

			.bannerGradient {
				background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,0) 100%);
				height: 100%;
				display: flex;
				align-items: flex-end;
				padding: 0 5vw;

				@media (min-width: 1025px) {
					display: grid;
					grid-template-columns: 1fr 700px 40px 240px 1fr;
					align-content: end;
					padding: 0;
				}

				@media (min-width: 1200px) {
					grid-template-columns: 1fr 740px 100px 300px 1fr;
				}

				@media (max-width: 767px) {
					align-items: center;
					justify-content: center;
					background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,.5) 100%);
				}

				.sectionTitle {
					grid-column: 2 / span 2;

					// IE11 fallback
					padding-left: 6vw;
					@supports (display: grid) {
						padding-left: 0;
					}

					h1 {
						color: white;
						margin: 0 0 18px;
						text-shadow: 0 2px 10px rgba(black, 0.15),
												 0 1px 2px rgba(black, 0.33);

						@media (max-width: 767px) {
	 						margin: 0;
	 					}
					}
					.breadcrumb {
						margin: 0 0 24px;

						@media (max-width: 767px) {
							display: none;
						}

						a {
							&, &:link, &:visited {
								color: white;
								margin-right: 8px;
								font-family: $bodyFont;
								font-size: 0.875em; //14px;
								font-weight: 500;
								text-transform: uppercase;
								letter-spacing: 1px;
							}

							&:hover, &:active, &:focus {
								color: $mint;
							}

							&::after {
								display: inline-block;
								content: ' ';
								background-image: url('https://di6zpoqlizfz.cloudfront.net/svgs/icon-right-white.svg');
								background-size: 12px 12px;
								height: 12px;
								width: 12px;
								margin-left: 10px;
							}

							&:last-child {
								color: $mint;

								&:hover, &:active, &:focus {
									cursor: default;
								}

								&::after {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}

	section.contentArea {
		grid-area: content;
		order: 2;
		margin-top: 50px;

		// IE11 fallback
		width: 88vw;
		padding: 0 6vw;
		@supports (display: grid) {
			width: auto;
			padding: 0;
		}

		@media (max-width: 1024px) {
			width: 90vw;
			margin: 50px 5vw 0 5vw;
		}

		h4 {
			font-weight: 600;
			color: $black;
		}

		figure {
			margin: 48px 0 48px 0;

	    figcaption {
	      margin: 0;
	      font-size: 13px;
	      padding: 8px 0;
	      display: block;
	      color: $textColor;
	      border-bottom: 1px solid $silver;
	    }
	  }

		.locations {

			.location {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid $lightsilver;

				@media (max-width: 450px) {
					flex-flow: wrap;
				}

				figure {
					width: 50%;
					margin: 0;

					@media (max-width: 450px) {
						width: 100%;
						margin: 0 0 24px;
					}

					img {
						width: 100%;
					}
				}

				.locationText {
					width: 45%;
					position: relative;

					span {
						top: -90px;
						display: block;
						position: absolute;
					}

					@media (max-width: 450px) {
						width: 100%;
					}
				}
			}
		}
	}

	.awards {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;

		.award {
			width: calc(25% - 32px);
			padding: 0 32px 32px 0;

			@media (max-width: 900px) {
				width: calc(33.333% - 32px);
			}

			@media (max-width: 600px) {
				width: calc(50% - 32px);
			}

			img {
				width: 100%;
			}
		}
	}

	.threeCol {
		display: flex;
		justify-content: space-between;

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}

		.column {
			width: 30%;

			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}

	.inlineCTA, .fileDownload {
		width: 92%;
		padding: 26px 4% 12px 4%;
		border: 1px solid $silver;
		margin: 12px 0 32px;

		.wrapper {
			max-width: 1140px;
			margin: 0 auto;
			text-align: center;

			@media(min-width: 500px) {
				display: grid;
				grid-gap: 30px;
				grid-template-columns: 1fr max-content;
				text-align: left;
				align-items: center;
			}
		}

		.content {
			grid-column: 1 / span 1;
			.h4 {
				font-size: 1.125em; // 18px;
			}
		}

		.actions {
			grid-column: 2 / span 1;
			align-self: center;

			font-size: .95em;
		}
	}

	aside {
		display: none;

		@supports (display: grid) {
			grid-area: aside;
			display: inline;
			z-index: 1;
			order: 1;
			margin-top: -48px;
		}

		@media (max-width: 1024px) {
			display: none;
		}

		nav.secondary {
			background: $black;
			padding: 32px 24px;
			margin: 0 0 60px;

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					margin-bottom: 10px;
					padding-bottom: 10px;
					font-size: .88em; //15px;
					line-height: 1.5;
					border-bottom: 1px solid rgba(white, .15);

					&:last-child {
						margin: 0;
						padding: 0;
						border: none;
					}

					a {
						&, &:link, &:visited {
							color: white;
						}

						&:hover, &:active, &:focus {
							color: $mint;
						}

						&.active {
							color: $mint;
							cursor: default;
						}
					}
				}
			}
		}

		.recommended {
			//padding: 60px 0 0 0;

			// Don't display on tablets and phones
			@media (max-width: 1024px) {
				display: none;
			}

			h3 {
				border-bottom: 1px solid $silver;
				padding-bottom: 12px;
				margin: 0 0 32px 0;
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {

					p.recommendedCategory {
						color: $tan;
						// font-family: $bodyFont;
						font-size: 12px;
						font-weight: 500;
						text-transform: uppercase;
						letter-spacing: 1px;
						margin: 0 0 4px;
					}

					p {
						line-height: 1.5;
						a.recommendedTitle {
							color: $black;
							margin: 0 0 26px;
							line-height: 1;

							&:hover {
								color: $link;
							}
						}
					}
				}
			}
		}
	}

	.formBlock {
		h3 {
			border-bottom: 1px solid $lightsilver;
		}

		p {
			font-size: em(15px);
			line-height: 23px;
		}

		&.margin-top {
			margin-top: 122px;
		}
	}

	.displayOnMobileOnly {
		display: none;
		@media (max-width: 1023px) {
			order: 2;
			display: inherit;
			flex-flow: column;
			width: 88vw;
			margin: 24px 6vw;
		}
	}

	aside.newsletterSide {
		grid-area: aside;
		order: 1;
		margin: 60px 0 0 0;

		@media (max-width: 1023px) {
			display: none;
		}

	  h3 {
	    margin: 0 0 12px;
	    font-size: 18px;
	    line-height: 24px;
	  }

	  p.small {
	    font-size: 13px;
	    line-height: 20px;
	    margin: 0;
	  }

	  .mediaInquiries {
	    width: 100%;

	    .authorCard {
	      border-bottom: 1px dotted $silver;
	      margin-bottom: 28px;
	      padding-bottom: 28px;

	      img {
	        border-radius: 50%;
	        max-width: 120px;
	        margin-bottom: 8px;
	      }

	      a.name {
	        display: block;
	        margin-bottom: 4px;
	        font-size: 13px;
	        line-height: 20px;
	        margin: 0;
	        font-weight: 600;
	        color: $black;

	        &:hover {
	          color: $teal;
	        }
	      }

	      a.contact {
	        display: block;
	        font-size: 13px;
	        line-height: 20px;
	        margin: 0;
	      }
	    }
	  }
	}
}
