header.header-simple {
	display: flex;
	justify-content: center;
	width: 100%;
	background: white;
	height: 90px;
	box-shadow: 0 1px 1px rgba($black,.1),
							0 2px 6px rgba($teal,.05);

	.logo {
		align-self: center;
		padding: 20px 0;

    img {
      height: 62px;
    }
	}
}

main.marketingList {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 60px 0 80px;
	width: 88vw;
	padding: 0 6vw;

	section.mList {
		max-width: 1000px;
		align-self: center;

		h1 {
			text-align: center;
			border-bottom: 1px dotted $silver;
			padding-bottom: 16px;
		}

		ul {
			column-count: 2;
			column-gap: 60px;
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: 1em;

			li {
				.entryType {
					font-family: $bodyFont;
					color: $tan;
					font-size: em(12px);
					font-weight: 500;
					text-transform: uppercase;
					letter-spacing: 1px;
					margin: 0 0 4px;
				}

				.h4 {
					margin: 0;
				}
			}
		}
	}
}

main.marketing {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 0;

	// Mobile layout will be served if browser doesn't support grid
	@media (min-width: 1025px) {
		margin: 0;
		display: grid;
		grid-template-columns: 5vw 180px 60px 1fr 60px 180px 5vw;
		grid-template-rows: 510px auto;
		grid-template-areas:
			"hero hero hero hero hero hero hero"
			". left .	content .	right ."
			"cta cta cta cta cta cta cta"
			". cards cards cards cards cards .";
	}

	@media (min-width: 1280px) {
		grid-template-columns: 1fr 180px 100px 640px 100px 180px 1fr;
	}

	section.hero {
		grid-area: hero;
		height: 510px;
		width: 100vw;
		position: relative;
		margin: 0;

		picture {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.bannerContent {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100%;

			.bannerGradient {
				background: linear-gradient(0deg, rgba(black,.75) 0%, rgba(black,.5) 100%);
				height: 100%;
				width: 90vw;
				display: flex;
				align-items: center;
				padding: 0 5vw;

				.sectionTitle {
					margin: 0 auto;
					max-width: 1000px;
					text-align: center;
					padding-top: 50px;

					.h5 {
						color: $lightMint;
					}
					h1 {
						color: white;
						margin: 0 0 20px;
						text-shadow: 0 2px 10px rgba(black, 0.15),
												 0 1px 2px rgba(black, 0.33);
						 @media (max-width: 767px) {
	 						font-size: em(42px);
	 					}
					}
				}
			}
		}
	}
	aside.leftAside {
		grid-area: left;
		margin-top: 50px;

		@media (max-width: 1024px) {
			display: none;
		}

		h3 {
			width: 100%;
			margin-bottom: 12px;
		}

		p.small {
      font-size: em(13px);
      line-height: 20px;
      margin: 0;
    }

		.authorCard {
	    border-bottom: none;
		}
	}

	section.contentArea {
		grid-area: content;
		margin: 50px 0;

		@media (max-width: 1024px) {
			width: 90vw;
			margin: 50px 5vw;
			order: 1;
		}

		.video {
			/*margin-top: -100px;*/
			box-shadow: 0px 3px 24px rgba(black, 0.2),
								  0px 4px 50px rgba(black, 0.1),
									0px 1px 3px  rgba(black, 0.1);
		}

		.whitePaperDetails {
			transform: translateY(-100px);
			margin-bottom: -60px;
			padding: 30px;
			background: $black;
			box-shadow: 0px 3px 24px rgba(black, 0.2),
									0px 4px 50px rgba(black, 0.1),
									0px 1px 3px  rgba(black, 0.1);

			.whitePaperText {
				.h4, h4 {
					color: white;
					margin: 0 0 8px;
				}
				p {
					color: white;
					margin-bottom: 13px;

					a {
						color: white;
					}
				}
			}
		}

		.eventDetails {
			transform: translateY(-120px);
			margin-bottom: -80px;
			padding: 6px 0;
			background: $black;
			box-shadow: 0px 3px 24px rgba(black, 0.2),
								  0px 4px 50px rgba(black, 0.1),
									0px 1px 3px  rgba(black, 0.1);

			.eventReg {
				padding: 30px;
				margin: 0;
				border-bottom: 1px solid white;

				form.marketingForm {

					.freeform-row .freeform-column .freeform-label {
						color: white;
					}

					.freeform-input {
						background: rgba(white,.05);
						border: 1px solid rgba(white,.2);
						color: white;

						&:active, &:visited, &:focus {
							background: rgba(white,.02);
							outline-color: $mint;
						}
					}

					button {
						border: 1px solid $mint;
						background: $teal;
						color: white;
					}
				}
			}

			.eventMeta {
				padding: 14px 0 0 0;
				margin: 0 30px;
				border-bottom: 1px solid rgba(white,.15);
				text-align: center;
				border-bottom: 1px solid rgba(white,.1);

				&:last-child {
					border: none;
				}
			}

			.h5 {
				color: $mint;
				text-align: center;
			}
			p {
				color: white;
				margin-bottom: 13px;

				a {
					color: white;
				}
			}
		}

		.speakers {
			width: 100%;
			margin: 0 0 40px 0;

			.h5 {
				text-align: center;
				border-bottom: 1px solid $lightsilver;
				padding-bottom: 24px;
			}

			.speakerBlock {
				border-bottom: 1px solid $lightsilver;
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				padding: 12px 0 10px;

				a.speakerPhoto {
					border: none;
					min-width: 60px;
					border-radius: 2em;
					margin-right: 8px;

					img {
						width: 68px;
						border-radius: 2em;
					}
				}

				h3 {
					width: auto;
					max-width: 200px;
					margin: 0 6px;
					padding: 0;
					line-height: 1.25;
				}

				p {
					width: 50%;
					margin: 0;
					padding: 0;

					@media (max-width: 768px) {
						font-size: 14px;
						line-height: 1.3;
					}
				}

			}
		}

		.intro p {
			margin: 0 auto 24px auto;
		}

		h4 {
			font-weight: 600;
			color: $black;
			max-width: 700px;
			margin-right: auto;
			margin-left: auto;
		}

		figure {
			margin: 0 32px 32px 32px !important;

			@media (max-width: 768px) {
				float: none;
				width: 100%;
				margin: 32px auto !important;
			}

	    figcaption {
				margin: 0;
	      font-size: em(13px);
	      padding: 8px 0;
	      display: block;
	      color: $textColor;
	      border-bottom: 1px solid $silver;
	    }
	  }

		p {
			max-width: 700px;
			margin-right: auto;
			margin-left: auto;
		}

		ul, ol {
			max-width: 700px;
			margin-right: auto !important;
			margin-left: auto !important;
			padding: 0;
			font-size: 1em;

			li {
				margin-left: 32px;
				margin-right: 32px;
			}
		}

		blockquote {
			text-align: center;
			color: $teal;

			p {
				font-size: inherit;
				max-width: inherit;
				width: inherit;
				line-height: inherit;
				margin: inherit;
			}
		}
	}

	aside.rightAside {
		grid-area: right;
		margin-top: 50px;

		@media (max-width: 1024px) {
			display: none;
		}

		h3 {
	    margin: 0 0 12px;
	    font-size: em(18px);
	    line-height: 24px;
			width: 100%;
			margin-bottom: 12px;
	  }

		p.small {
      font-size: em(13px);
      line-height: 20px;
      margin: 0;
    }

		.share {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			border-bottom: 1px dotted $silver;
	    margin-bottom: 28px;
	    padding-bottom: 28px;

			@media (max-width: 920px) {
				display: none;
			}

			button {
				margin-right: 10px;
			}
		}
	}

	section.cta {
		grid-area: cta;
		margin-bottom: 0;

		@media (max-width: 1024px) {
			order: 4;
		}
	}

	section.marketingCardsContainer {
		grid-area: cards;

		display: flex;
		flex-flow: column wrap;
	  width: 90vw;
	  margin: 60px auto 52px;
	  padding: 0 5vw;
	  max-width: 1080px;

		@media screen and (max-width: 1280px) {
			width: 100vw;
			padding: 0;
			max-width: 920px;
		}

		@media screen and (max-width: 1024px) {
			max-width: 90%;
			order: 5;
		}

		h3 {
			grid-area: category;
			border-bottom: 1px solid $lightsilver;
			position: relative;
	    margin: 0 24px;
			padding-bottom: 10px;

			@media screen and (max-width: 1024px) {
				margin: 0 0 10px 0;
			}

			a {
				&, &:link, &:visited {
					position: absolute;
					right: 0;
					font-family: $bodyFont;
					font-size: em(14px);
					font-style: normal;
					font-weight: 500;
					text-transform: uppercase;
					letter-spacing: 1px;
				}
			}
		}
	}
}

.inlineCTA.center {
	text-align: center;
}
.inlineCTA.left {
	text-align: left;
}
.inlineCTA.right {
	text-align: right;
}
