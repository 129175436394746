
section.dataPoints {
  width: 100vw;
	background: rgba($lightsilver, .5);
	padding: 60px 0 48px;

	@media(max-width: 1200px) {
		width: 90vw;
		padding: 60px 5vw 48px 5vw;
	}

	.wrapper {
		max-width: 1140px;
		margin: 0 auto;
    display: flex;
		text-align: center;
    justify-content: space-between;

    @media(max-width: 1023px) {
      flex-wrap: wrap;
    }

    .dataPoint {
      width: calc(33.333% - 60px);
      text-align: center;
      margin-right: 30px;
      padding-right: 30px;
      border-right: 1px solid $lightsilver;

      @media(max-width: 1023px) {
    		width: 100%;
        border: none;
        margin: 0 0 20px;
        padding: 0 0 20px;
        border-bottom: 1px solid $lightsilver;
    	}

      &:last-child {
        width: 33.333%;
        border: none;
        margin: 0;
        padding: 0;

        @media(max-width: 1023px) {
      		width: 100%;
          margin: 0 0 20px;
      	}
      }

      h1 {
        color: $teal;
        line-height: .9;
        margin: 0 0 16px;
      }

      h4 {
        margin: 0 0 18px;
      }

      p {
        font-size: $mobileTextSize;
        line-height: $mobileLineHeight;

        a {
          &, &:link, &:visited {
        		font-weight: 400;
        		font-size: $mobileTextSize;
        		font-family: $bodyFont;
        		line-height: $mobileLineHeight;
        		color: white;
        		background: $link;
        		padding: 10px 24px;
        		min-width: 120px;
        		text-align: center;
        		display: inline-block;
        		border-radius: 40px;
        		box-shadow: 0px 0px 0px 1px rgba($silver,0),
        								0px 0px 0px 2px rgba(white,0);
        	}
        	&:hover {
        		background: darken($link, 5%);
        		box-shadow: 0px 0px 0px 1px rgba($silver,.5),
        								0px 0px 0px 2px rgba(white,.5);
        	}

        	&:active, &:focus {
        		box-shadow: 0px 0px 0px 8px rgba($silver,1),
        								0px 0px 0px 18px rgba(white,0);
        	}
        }
      }
    }
  }
}
