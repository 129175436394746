// Accessibility
#skipNavigation {
	position:relative;
	z-index:100;
	float:left;
	
	a, a:link, a:visited {
		position:absolute;
		z-index:1;
		top:-999em;	
		left:0px;
		
		display:block;
		width:200px;
		padding:7px 10px;
		border:1px solid #CCC;
		outline:0;
		background:#FFF;
	
    color:#333;
    font-size: 16px;
		line-height:1.2;
		font-weight:bold;
		text-decoration:none;
	}
	a:focus, a:active {
		top: 0px;
		*zoom:1
	}
	a:hover, a:active, a:focus {
		background: $link;
		color: #FFF;
	}
}

// Edit page
.edit {
  position: fixed;
  top: calc(50% - 60px);
  left: 0;
  background: black;
  width: 26px;
  height: 120px;
  z-index: 10000;
  margin: 0 0 0 -5px;
  padding-right: 6px;
  text-align: center;
  writing-mode: vertical-rl;

  a {
    &, &:link, &:visited {
      color: white;
      font-size: 13px;
    }

    &:hover, &:active, &:focus {
      opacity: .75;
    }
  }
}

// Responsive video
.video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	margin: 48px 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Arrow styles for text links

.rightArrowTeal {
  svg {
    width: 15px;
    margin: 0 0 -1px 2px;
    g {
      fill: $teal;
    }
  }
  &:hover {
    svg {
      margin: 0 0 -1px 6px;
      g {
        fill: $linkHover;
      }
    }
  }
}

.rightArrowMint {
  svg {
    width: 15px;
    margin: 0 0 -1px 2px;
    g {
      fill: $mint;
    }
  }
  &:hover {
    svg {
      margin: 0 0 -1px 6px;
      g {
        fill: $lightMint;
      }
    }
  }
}

.leftArrowTeal {
  svg {
    width: 15px;
    margin: 0 2px -1px 0;
    g {
      fill: $teal;
    }
  }
  &:hover {
    svg {
      transform: translateX(-4px);
      g {
        fill: $linkHover;
      }
    }
  }
}

.bottom {
	margin-top: 60px;
}

span.time {
	font-family: $bodyFont;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 0;
	color: $textColor;
}

a.archiveButton {
	text-align: center;
	margin: 60px auto 0 auto;
}

button.leftArrowTeal {
	font-size: $mobileTextSize;
	font-family: $bodyFont;
	color: $link;
	padding: 0;
	border: none;
	cursor: pointer;
	margin: 0 0;

	&:hover, &:active, &:focus {
		color: $linkHover;
	}
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}