.jobs {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  margin: 80px 0 0;

  // Mobile layout will be served if browser doesn't support grid
  @media (min-width: 1025px) {
    margin: 100px 0 0;
    display: grid;
    grid-template-columns: 1fr 24px 980px 24px 1fr;
    grid-template-rows: 250px repeat(auto);
    grid-template-areas:
    "banner banner banner	banner banner"
    "nav nav nav nav nav"
    ". . content . .";
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 24px 1140px 24px 1fr;
  }

  section.banner {
    grid-area: banner;
    height: 250px;
    width: 100vw;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: none;
    margin: 0;

    @media (max-width: 767px) {
      height: 180px;
    }

    picture {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bannerContent {
      .bannerGradient {
        background: linear-gradient(0deg, rgba(black,.25) 0%, rgba(black,0) 100%);
      }
    }
  }

  nav.horizontalNav {
    grid-area: nav;
    background-color: #f6f6f6;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
    border-bottom: 1px solid $silver;
    display: flex;
    width: 92vw;
    padding: 0 4vw;
    justify-content: center;

    a {
      &, &:link, &:visited {
        padding: 20px 0 16px;
        margin: 0 16px;
        text-align: center;
        color: $black;
        border-bottom: 4px solid transparent;
      }

      &.activee {
        color: darken($teal, 10%);
        border-bottom: 4px solid $teal;
        font-weight: 500;
      }

      &:hover, &:active, &:focus {
        color: darken($teal, 10%);
      }

      @media (max-width: 767px) {
        margin: 0 10px;
        font-size: em(14px);
        font-weight: 500;
      }
    }
  }

  section.jobContent {
    grid-area: content;
    width: 100%;
    margin: 48px 0;
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    position: relative;

    @media (max-width: 1024px) {
			width: 90vw;
			margin: 50px 5vw 0 5vw;
		}

    .intro {
      max-width: 750px;
      text-align: center;
      margin: 0 auto;
    }

    button {
      width: 100%;
      background: white;
      margin-bottom: 32px;
      text-align: left;

      &.top {
        padding-bottom: 24px;
        margin-bottom: 48px;
        border-bottom: 1px dotted $silver;
      }

      &.bottom {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px dotted $silver;
      }
    }

    .jLeftColumn {
      width: calc(29% - 50px);
      margin: 0 6% 184px 0;
      text-align: center;
      position: sticky;
      top: 100px;

      .jTitle {
        background: $lightsilver;
        padding: 25px;
      }

      .jShare {
        margin: 32px 0 0 0;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        h3 {
          width: 100%;
        }
        button {
          width: 32px;
          height: 32px;
          margin: 0 8px;
        }
      }

      @media (max-width: 1280px) {
        width: 100%;
        margin: 0 0 32px 0;
        position: relative;
        top: auto;
      }

      h2 {
        font-size: em(32px);
        margin: 0;
      }

      .jLocations {
        span {
          margin-right: 3px;
        	font-size: em(12px);
        	font-weight: 500;
        	text-transform: uppercase;
        	letter-spacing: 1px;

          &:after {
            content: '|';
            margin-left: 6px;
            color: $lightgray;

            @media (max-width: 767px) {
             display: none;
            }
          }

          &:last-child {
            margin: 0;

            &:after {
              content: '';
              margin-left: 0;

              @media (max-width: 767px) {
               display: none;
              }
            }
          }
        }
      }

      a.button {
        &, &:link, &:visited {
          margin: 16px 0 8px 0;
        }
      }
    }

    .jContent {
      width: 67%;
      margin-bottom: 48px;

      @media (max-width: 1280px) {
        width: 100%;
        margin: 0 0 32px 0;
      }

      h4 {
        font-weight: 600;
      }
    }

    .apply {
      display: none;

      @media (max-width: 768px) {
        display: inherit;
        padding: 32px 0 48px;
        background: $lightsilver;
        padding: 40px 0;
        margin: 40px 0;
        text-align: center;

        h3 {
    			font-size: em(30px);
          display: inline-block;
          font-weight: 600;
          margin-right: 20px;

          @media (max-width: 767px) {
           display: block;
           margin: 0 0 18px 0;
          }
        }
      }
    }
  }

  .jobSearchTable {
    width: 100%;

    td {
      @media (min-width: 1024px) {
        padding-top: 18px;
        padding-bottom: 18px;
      }
    }

    td.jobTitle {
      font-weight: 600;
    }
    td.jobLocations span {
      margin-right: 8px;

      @media (max-width: 767px) {
       margin: 0 0 4px 0;
       display: block;
      }

      &:after {
        content: '|';
        margin-left: 10px;
        color: $silver;

        @media (max-width: 767px) {
         display: none;
        }
      }

      &:last-child {
        margin: 0;

        &:after {
          content: '';
          margin-left: 0;

          @media (max-width: 767px) {
           display: none;
          }
        }
      }
    }
    td.jobLink {
      font-weight: 600;
      @media (min-width: 1024px) {
       width: 120px;
      }
    }
  }


}
