main.newsArticle {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0 0 0;

	article.layout {
		display: grid;
		grid-template-columns: 180px 640px 180px;
		grid-column-gap: 70px;
		grid-row-gap: 40px;
		justify-content: center;
		grid-template-areas:
		"title 	title			title"
		"left 	article 	right";

		@media (max-width: 1280px) {
			grid-template-columns: 5% 140px 60px 1fr 60px 140px 5%;
			grid-column-gap: 0;
			grid-template-areas:
			". title title	title	 title title ."
			". left  .	article . right .";
		}

		@media (max-width: 920px) {
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 0;
			grid-template-areas:
			"title title"
			"article article"
			"left right";
		}

		.articleTitle {
			grid-area: title;
			text-align: center;
			width: 80%;
			padding: 0 10% 40px 10%;
			margin-top: 80px;
			border-bottom: 1px solid $silver;

			@media (max-width: 1280px) {
				width: 90%;
				padding: 0 5% 40px 5%;
			}

			@media (max-width: 1280px) {
				margin-bottom: 40px;
			}

			@media (max-width: 767px) {
				width: 86vw;
				padding: 0 7vw 40px 7vw;
			}

			.h5 {
				color: $tan;
			}

			h1 {
				margin: 0 0 12px;
				font-size: em(40px);
				line-height: 45px;
			}
		}

		.h3 {
			margin: 0 0 2px;
			font-size: em(18px);
			line-height: 24px;
		}

		p.small {
			font-size: em(13px);
			line-height: 20px;
			margin: 0;
		}

		aside.leftAside {
			grid-area: left;

			h3 {
				margin: 0 0 12px;
				font-size: em(18px);
				line-height: 24px;
			}

			@media (max-width: 920px) {
				border-right: 1px solid $silver;
				align-items: center;
				text-align: center;
				align-self: center;
				padding: 32px 0;
			}

			.authorCard {
				border-bottom: 1px dotted $silver;
				margin-bottom: 28px;
				padding-bottom: 28px;

				@media (max-width: 1023px) {
					border: none;
					margin-bottom: 0;
					padding: 0 20px;
				}

				img {
					border-radius: 50%;
					max-width: 120px;
					margin-bottom: 8px;

					@media (max-width: 1023px) {
						display: none;
					}
				}

				a.name {
					&, &:link, &:visited {
						display: block;
						margin-bottom: 4px;
						font-size: em(13px);
						line-height: 20px;
						margin: 0;
						font-weight: 600;
						color: $black;
					}
					&:hover, &:active, &:focus {
						color: $teal;
					}
				}

				a.contact {
					&, &:link, &:visited {
						display: block;
						font-size: em(13px);
						line-height: 20px;
						margin: 0;
					}
				}
			}
		}

		section.contentArea {
			grid-area: article;
			max-width: 1140px;
			margin: 0 auto 40px auto;

			@media (max-width: 920px) {
				width: 86vw;
				padding: 0 7vw 40px 7vw;
				border-bottom: 1px solid $silver;
				margin: 0;
			}

			table {
				width: 130%;
				margin-left: -15%;

				@media (max-width: 920px) {
					width: 100%;
					margin-left: 0;
				}

			}

			figure.leadPhoto {
				margin: 0 0 32px 0;

				figcaption {
					margin: 0;
					font-size: em(13px);
					padding: 8px 0;
					display: block;
					color: $textColor;
					border-bottom: 1px solid $silver;
				}
			}

			.h4 {
				font-weight: 600;
				color: $black;
				max-width: 700px;
				margin-right: auto;
				margin-left: auto;
			}

			figure {
				margin: 0 32px 32px 32px;
				@media (max-width: 768px) {
					float: none;
					width: 100%;
					margin: 32px auto;
				}

				figcaption {
					margin: 0;
					font-size: em(13px);
					padding: 8px 0;
					display: block;
					color: $textColor;
					border-bottom: 1px solid $silver;
				}
			}

			ol,
			ul {
				padding: 0;

				li {
					margin-left: 32px;
					margin-right: 32px;
				}
			}

			blockquote {
				text-align: center;
				color: $teal;

				p {
					font-size: inherit;
					max-width: inherit;
					width: inherit;
					line-height: inherit;
					margin: inherit;
				}
			}
		}

		aside.rightAside {
			grid-area: right;

			@media (max-width: 920px) {
				align-items: center;
				text-align: center;
				align-self: center;
			}

			.newsletter {
				border-bottom: 1px dotted $silver;
				margin-bottom: 28px;
				padding-bottom: 28px;

				@media (max-width: 920px) {
					border-bottom: none;
					margin-bottom: 0;
					padding: 0 20px;
				}
				h3 { 
					font-size: 1.125em;
					margin-bottom: 0px;
				}
				a.smButton {
					&, &:link, &:visited {
						font-size: em(13px);
						font-weight: 500;
						display: inline-block;
						padding: 6px 18px 7px 18px;
						border-radius: 5em;
						background: $link;
						color: white;
						margin-top: 10px;
					}
					&:hover, &:active, &:focus {
						background: $linkHover;
					}
				}
			}

			.share {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;

				@media (max-width: 920px) {
					display: none;
				}

				h3 {
					width: 100%;
					margin-bottom: 10px;
					font-size: 1.125em;
				}
				button {
					margin-right: 10px;
				}
			}
		}
	}

	section.cta {
		@media (max-width: 920px) {
			display: none;
		}
	}
}
