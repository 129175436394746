main.insightsLanding {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 120px auto 0 auto;

	@media (max-width: 1024px) {
		margin: 80px 0 24px 0;
	}

	section.pageTitle {
		width: 90vw;
		max-width: 900px;
		margin: 0 auto;
		padding: 0 5vw;
		text-align: center;

		h1 {
			margin-bottom: 16px;
		}
	}

	section.insights {
		display: flex;
		flex-flow: row wrap;
		width: 90vw;
		margin: 24px auto 32px;
		padding: 0 5vw;
		max-width: 1140px;
		justify-content: space-between;

		height: 900px;

		@supports (display: grid) {
			height: auto;
		}

		@media (max-width: 1024px) {
			margin: 0;
		}

		.feature {
			width: 68%;

			@media (max-width: 1140px) {
				width: 63%;
			}

			@media (max-width: 1023px) {
				width: 100%;
			}

			figure.zoom {
				position: relative;
		    max-width: 100%;
		    margin: 0 0 32px;
				padding: 0;
		    overflow: hidden;

				img {
					display: block;
		      width: 100%;
		      height: auto;
		      transition: all 0.3s ease;
					overflow: hidden;

				}

			}

			&:hover figure.zoom img {
				transform: scale(1.05);
			}

			&:hover .h2 a {
				&, &:link, &:visited {
					color: $teal;
				}
			}

			.h2 a {
				&, &:link, &:visited {
					color: inherit;
				}
				&:hover, &:active, &:focus {
					color: $link;
				}
			}

			.h3 {
				border-bottom: 1px solid $silver;
				padding-bottom: 8px;
				margin: 0 0 32px;
				a {
					&, &:link, &:visited {
						color: inherit;
					}
					&:hover, &:active, &:focus {
						color: $link;
					}
				}
			}

			p.h5 {
				color: $tan;
				font-weight: 500;
				margin-bottom: 0;

				a {
					&, &:link, &:visited {
						color: inherit;
					}
					&:hover, &:active, &:focus {
						color: $link;
					}
				}
			}

			p {
				margin: 0 0 12px;
				line-height: 1.5;
			}
		}

		.topTopics {
			width: 24%;

			@media (max-width: 1140px) {
				width: 30%;
			}

			@media (max-width: 1023px) {
				display: none;
			}

			.h3 {
				border-bottom: 1px solid $silver;
				padding-bottom: 8px;
				margin: 0 0 32px;
				a {
					&, &:link, &:visited {
						color: inherit;
					}
					&:hover, &:active, &:focus {
						color: $link;
					}
				}
			}
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				font-size: 1em;

				li {
					margin-bottom: 26px;
					display: grid;
					grid-template-columns: 75px 1fr;
					grid-column-gap: 20px;
					border-bottom: 1px solid rgba($silver, .5);
					padding-bottom: 14px;

					&:last-child {
						border: none;
					}

					img {
						grid-column: 1 / span 1;
					}

					.topicText {
						grid-column: 2 / span 1;

						.h5 {
							a {
								&, &:link, &:visited {
									color: $tan;
									font-weight: 500;
								}
								&:hover, &:active, &:focus {
									color: $link;
								}
							}
						}

						.h4 {
							margin-bottom: 0;
							font-size: 1em;

							a {
								&, &:link, &:visited {
									color: $black;
									font-weight: 600;
								}
								&:hover, &:active, &:focus {
									color: $link;
								}
							}
						}
					}
				}
			}
		}
	}

	section.latestInsights {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		width: 90vw;
		margin: 24px auto;
		padding: 0 5vw;
		max-width: 1140px;

		height: 600px;

		@supports (display: grid) {
			height: auto;
		}

		.latestInsight {
			width: 46%;
			display: flex;
			flex-flow: column wrap;
			margin-bottom: 40px;

			@media (max-width: 1023px) {
				width: 100%;
				margin-bottom: 24px;
			}

			h3 {
				width: 100%;
				border-bottom: 1px solid $lightsilver;
				position: relative;
				margin: 0;
				padding-bottom: 6px;

				a {
					&, &:link, &:visited {
						position: absolute;
						right: 0;
						font-family: $bodyFont;
						font-size: 12px;
						border: 1px solid $lightsilver;
						border-radius: 2em;
						padding: 6px 12px;
						font-style: normal;
						font-weight: 500;
						text-transform: uppercase;
						letter-spacing: 1px;
						line-height: 1;
					}
					&:hover, &:active, &:focus {
						border: 1px solid $silver;
					}
				}
			}

			.insightCard {
				width: 100%;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: center;

				padding: 24px;
				transform: translateX(-24px);

				@media (max-width: 767px) {
					padding: 24px 0 12px 0;
					transform: translateX(0);
					align-items: flex-start;
				}

				&:hover {
					z-index: 2;
					box-shadow: 0px 3px 60px rgba($black,.25),
											0px 1px 2px rgba($black,.05);
				}

				figure {
					width: 48%;
					margin: 0;

					a img {
						width: 100%;
					}

					@media (max-width: 540px) {
						width: 36%;
					}
				}

				.videoThumb {
					position: relative;
					padding-bottom: 24%; /* 16:9 */
					padding-top: 25px;
					margin-bottom: 12px;
					height: 0;
					width: 48%;

					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}

				.insightCardText {
					width: 48%;

					@media (max-width: 540px) {
						width: 60%;
					}

					h4 {
						margin: 0 0 4px;
						font-weight: 500;
					}
				}

				&:hover {
					z-index: 2;
					box-shadow: 0px 3px 60px rgba($black,.25),
											0px 1px 2px rgba($black,.05);
				}
			}
		}
	}
}
