footer {
  align-self: center;
  margin: 0;
  padding: 60px 5% 20px 5%;
  width: 90%;
  display: flex;
  flex-flow: column wrap;
  border-top: 1px solid $silver;
  background-color: #f6f6f6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");

  @media (max-width: 620px) {
    padding: 30px 5%;
  }

  .footerTop {
    display: flex;
    justify-content: space-between;

    @media (max-width: 620px) {
      flex-wrap: wrap;
    }

    div {
      flex-basis: 17.5%;
      padding: 24px 0;

      @media (max-width: 620px) {
        padding: 24px 0 0 0;
      }

      p {
        font-size: .9375em; //15px;
        line-height: 1.5;
      }

      a {
        &, &:link, &:visited {
          display: block;
          margin-bottom: 18px;
          font-size: 18px;
          color: $gray;
        }

        &:hover, &:active, &:focus {
          color: $link;
        }

        &.phone {
          color: $tan;
        }

        &.contact {
          font-weight: 500;
          color: $link;

          &:hover, &:active, &:focus {
            color: $linkHover;
          }

          @media (max-width: 620px) {
            margin: 0;
          }
        }
      }

      h5 {
        color: $tan;
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 20px;
        }
      }
    }

    div.rowOne {
      flex-basis: 38%;

      img.footerLogo {
        margin-bottom: 20px;
        height: 60px;
      }

      @media (max-width: 620px) {
        flex-basis: 100%;
      }
    }

    div.rowTwo, div.rowThree, div.rowFour {
      @media (max-width: 620px) {
        display: none;
      }
    }
  }

  .footerLocations {
    display: flex;
    border-top: 1px solid $silver;
    border-bottom: 1px solid $silver;
    margin: 50px 0;
    padding: 20px 0;

    @media (max-width: 620px) {
      flex-flow: column;
      padding: 0;
    }
    
    h5 {
      flex-basis: 20%;

      margin: 0;
      width: 100%;
      text-align: center;
      letter-spacing: 0px;
      text-transform: none;

      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $silver;
      flex-flow: wrap;

      @media (max-width: 620px) {
        border-right: none;
        border-bottom: 1px solid $silver;
        padding: 10px 0;
        justify-content: flex-start;
      }

      @media (max-width: 1024px) {
        font-size: 1.15em; // 18px;
      }

      @media (max-width: 820px) {
        font-size: .9375em; //15px;
      }

      @media (max-width: 620px) {
        width: inherit;
        font-size: 1.15em; // 18px;
      }
      
      &:last-child {
        border: none;
      }
    }

    a {
      &, &:link, &:visited {
        color: $gray;
        font-weight: normal;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (min-width: 620px) {
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }

      &:hover, &:active, &:focus {
        color: $teal;
      }


      img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 2em;
        margin: 0 0 6px;
        border: 2px solid transparent;


        @media (max-width: 620px) {
          margin: 0 12px 0;
        }
      }

      &:hover, &:active, &:focus {
        color: $link;
        img {
          border: 2px solid $teal;
        }
      }
    }
  }

  .footerBottom {
    display: flex;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
    }

    .copyright {
      flex-basis: 70%;

      @media (max-width: 1023px) {
        flex-basis: 100%;
      }

      p {
        font-size: 15px;
        line-height: 1.5;

        a {
          &, &:link, &:visited {
            color: $gray;
          }

          &:hover, &:active, &:focus {
            color: $link;
          }
        }
      }
    }
    .social {
      flex-basis: 30%;
      text-align: right;

      @media (max-width: 1023px) {
        flex-basis: 100%;
        text-align: left;
      }

      a {
        margin-left: 30px;

        @media (max-width: 1023px) {
          margin: 0 30px 0 0;
        }

        img {
          height: 20px;
        }

        &:hover, &:active, &:focus {
          img {
            opacity: .9;
            transform: translateY(-1px);
          }
        }
      }
    }
  }
}
