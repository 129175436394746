section.banner {
  height: 250px;
  width: 100vw;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: none;
  margin: 0;
  max-width: 100%;

  @media (max-width: 767px) {
    height: 180px;
  }

  picture {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bannerContent {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    .bannerGradient {
      background: linear-gradient(0deg, rgba(black,.6) 0%, rgba(black,.25) 100%);
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 0 5vw;


      @media (min-width: 1025px) {
        display: grid;
        grid-template-columns: 1fr 90vw 1fr;
        align-content: end;
        padding: 0;
      }

      @media (min-width: 1280px) {
        grid-template-columns: 1fr 1140px 1fr;
      }

      @media (max-width: 767px) {
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,.5) 100%);
      }

      .sectionTitle {
        grid-column: 2 / span 2;

        h1, .h1, .section {
          color: white;
          font-family: $headerFont;
          font-size: 3.75em; //60px;
          line-height: 1.05;
          font-weight: 700;
        
          margin: 0 0 18px;
          text-shadow: 0 2px 10px rgba(black, 0.15),
                       0 1px 2px rgba(black, 0.33);

          @media (max-width: 767px) {
            margin: 0;
            font-size: 3em; //48px;
          }
        }
        .breadcrumb {
          margin: 0 0 24px;

          @media (max-width: 767px) {
            display: none;
          }

          a {
            &, &:link, &:visited {
              color: white;
              margin-right: 8px;
              font-family: $bodyFont;
              font-size: 0.875em; //14px;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 1px;
            }

            &:hover, &:active, &:focus {
              color: $mint;
            }

            &::after {
              display: inline-block;
              content: ' ';
              background-image: url('https://di6zpoqlizfz.cloudfront.net/svgs/icon-right-white.svg');
              background-size: 12px 12px;
              height: 12px;
              width: 12px;
              margin-left: 10px;
            }

            &:last-child {
              color: $mint;

              &:hover, &:active, &:focus {
                cursor: default;
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
