// Table Styles
table {
  width: 100%;
  margin-bottom: 56px;
  box-shadow: 0 1px 3px 0 rgba($black, .1),
              0 4px 20px 0 rgba($black, .15);

  tr {
    background: white;

    &:nth-child(even) {
      background: lighten($mint, 28%);
      border-bottom: 1px solid $lightsilver;
      border-top: 1px solid $lightsilver;
    }

    th {
      background: $teal;
			color: white;
			padding: 12px 14px;
			text-transform: uppercase;
			font-size: em(12px);
			font-style: normal;
			font-weight: 500;
			letter-spacing: 1px;
      text-align: left;
      vertical-align: middle;

      @media(max-width: 767px) {
        padding: 9px 10px;
        font-size: em(11px);
      }

    }

    td {
      padding: 12px 14px;
      font-size: em(14px);
      color: $gray;
      vertical-align: middle;

      @media(max-width: 767px) {
        padding: 9px 10px;
        font-size: em(12px);
      }

      p, ul, ol {
				font-size: em(14px);

				@media(max-width: 767px) {
	        font-size: em(12px);
	      }
			}
    }
  }
}
