.careers {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  margin: 80px 0 0;

  // Mobile layout will be served if browser doesn't support grid
  @media (min-width: 1025px) {
    margin: 100px 0 0;
    display: grid;
    grid-template-columns: 1fr 24px 980px 24px 1fr;
    grid-template-rows: 250px repeat(auto);
    grid-template-areas:
    "banner banner banner	banner banner"
    "nav nav nav nav nav"
    ". . intro . ."
    "team team team team team"
    "story story story story story";
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 24px 1140px 24px 1fr;
  }

  section.banner {
    grid-area: banner;
    height: 250px;
    width: 100vw;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: none;
    margin: 0;
    max-width: 100%;

    @media (max-width: 767px) {
      height: 180px;
    }

    picture {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bannerContent {
      .bannerGradient {
        background: linear-gradient(0deg, rgba(black,.25) 0%, rgba(black,0) 100%);
      }
    }
  }

  nav.horizontalNav {
    grid-area: nav;
    background-color: #f6f6f6;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
    border-bottom: 1px solid $silver;
    display: flex;
    width: 92vw;
    padding: 0 4vw;
    justify-content: center;

    a {
      &, &:link, &:visited {
        padding: 20px 0 16px;
        margin: 0 16px;
        text-align: center;
        color: $black;
        border-bottom: 4px solid transparent;

        @media (max-width: 767px) {
          margin: 0 10px;
          font-size: em(14px);
          font-weight: 500;
        }
      }
      &.activee {
        color: darken($teal, 10%);
        border-bottom: 4px solid $teal;
        font-weight: 500;
      }

      &:hover, &:active, &:focus {
        color: darken($teal, 10%);
      }
    }
  }

  section.careersIntro {
    grid-area: intro;
    width: 100%;
    margin: 48px 0;

    @media (max-width: 1024px) {
			width: 90vw;
			margin: 50px 5vw 0 5vw;
		}

    .careersIntroText {
      position: relative;
      background: white;
      width: calc(90% - 80px);
      margin: -66px 5% 0 5%;
      padding: 32px 40px;

      @media (max-width: 767px) {
        width: calc(92% - 40px);
        margin: -32px 4% 0 4%;
        padding: 24px 20px;
  		}
    }
  }

  section.careersTeam {
    grid-area: team;
    width: 100%;
    background: lighten($lightsilver, 5%);
    padding: 60px 0;
    border-bottom: 1px solid darken($silver, 10%);

    .careersIntroText {
      width: 680px;
      margin: 0 auto;
      text-align: center;

      @media (max-width: 767px) {
  			width: 90vw;
  			margin: 0 5vw;
  		}
    }

    .careersTeamCards {
      display: flex;
      width: 1188px;
      margin: 0 auto;
      justify-content: space-between;

      @media (max-width: 1280px) {
  			width: 90vw;
  			margin: 0 5vw;
  		}

      @media (max-width: 1024px) {
  			flex-wrap: wrap;
  		}

      a.careerBioThumb {
        &, &:link, &:visited {
          display: flex;
          width: calc(33.333% - 48px);
          padding: 24px;
          flex-flow: column;
          justify-content: flex-start;
          position: relative;

          transition: all 0.2s ease-out;
          box-shadow: 0px 2px 44px rgba($black, 0),
                      0px 1px 3px rgba($black, 0);

          @media screen and (max-width: 1280px) {
            width: calc(32% - 48px);
          }

          @media screen and (max-width: 1024px) {
            width: calc(50% - 24px);
            padding: 12px;
          }
        }

  			.photo {
  				width: 100%;

  				img {
  					max-width: 100%;
  				}
  			}

  			.text {
  				width: 100%;
  				margin-right: 30px;
  				position: relative;

  				h3 {
  					margin: 12px 0 6px 0;
  					width: calc(100% - 40px);
  					color: $black;
  					font-family: $bodyFont;
  					font-weight: 600;
            line-height: 1.25;
  				}

  				p {
  					margin: 0;
  					color: $gray;
  					font-size: em(16px);
  					line-height: 24px;

  					@media screen and (max-width: 768px) {
  						font-size: em(14px);
  						line-height: 22px;
  					}

  					&.email {
  						border-top: 1px solid darken($silver, 5%);
  						margin-top: 12px;
  						padding-top: 10px;
  						color: $teal;

              @media screen and (max-width: 767px) {
    						display: none;
    					}
  					}
  				}
  			}

  			.eye {
  				opacity: 0;
  			}
  			@supports (display:grid) {
  				.eye {
  					opacity: inherit; // RESET IE11 FIX
  				}
  			}

  			.eye {
  				position: absolute;
  				right: 0;
  				top: 18px;

  				@media screen and (max-width: 767px) {
  					display: none;
  				}

  				svg {
            fill: darken($silver, 5%);
            // fill: $black;
  					width: 20px;
  					transition: all 0.2s ease-out;
  				}
  			}

  			&:hover, &:active, &:focus {
  				cursor: pointer;
  				z-index: 2;
  				box-shadow: 0px 3px 60px rgba($black,.25),
                      0px 1px 2px rgba($black,.05);

          .h3 {
            color: $teal;
          }

          .eye svg {
            fill: $teal;
          }
  			}
  		}
    }
  }

  section.photoFeature {
    grid-area: story;
    width: 100%;
  }
}
