main.articleCategory {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0;
	@media (max-width: 1024px) {
		margin: 80px 0 24px;
	}

	section.banner {
		height: 250px;
		width: 100vw;
		background: $black;
		position: relative;
		background-size: cover;
		background-position: center center;
		background-repeat: none;
		margin: 0;

		@media (max-width: 767px) {
			height: 180px;
		}

		.bannerGradient {
			background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,0) 100%);
			height: 100%;
			width: 100vw;
			position: relative;

			.sectionTitle {
				padding: 0 5vw;
				max-width: 1140px;
				margin: 0 auto;
				height: 100%;
				position: relative;

				.sectionTitleBlock {
					position: absolute;
					bottom: 16px;

					h1 {
						color: white;
						margin: 0 0 18px;
						text-shadow: 0 2px 10px rgba(black, 0.15), 0 1px 2px rgba(black, 0.33);
						@media (max-width: 767px) {
							margin: 0;
						}
					}

					.h5 {
						color: white;
					}
				}
			}
		}
	}

	section.categoryArticles {
		display: flex;
		flex-flow: row wrap;
		width: 90vw;
		margin: 40px auto 32px;
		padding: 0 5vw;
		max-width: 1140px;
		justify-content: space-between;

		@media (max-width: 1023px) {
			margin-bottom: 0;
		}

		.categoryList {
			width: 62%;
			display: flex;
			flex-flow: column wrap;
			margin-bottom: 40px;
			padding-right: 7%;
			border-right: 1px solid $lightsilver;

			@media (max-width: 1023px) {
				width: 100%;
				padding-right: 0;
				margin-bottom: 20px;
				border: none;
			}

			.categoryCard {
				width: 100%;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: flex-start;
				padding: 18px;
				transform: translateX(-18px);
				border-bottom: 1px dotted $silver;
				padding-bottom: 18px;

				&:nth-child(10n) {
					border-bottom: none;
				}

				@media (max-width: 767px) {
					padding: 24px 0 12px;
					transform: translateX(0);
					align-items: flex-start;
				}

				&:hover {
					z-index: 2;
					box-shadow: 0 3px 60px rgba($black,.25), 0 1px 2px rgba($black,.05);
				}

				figure {
					width: 25%;
					margin: 0;
					@media (max-width: 540px) {
						width: 36%;
					}
				}

				.categoryCardText {
					width: 72%;
					padding-top: 4px;
					@media (max-width: 540px) {
						width: 60%;
					}

					.h4 {
						margin: 0 0 4px;
						padding-top: 0;

						a {
							font-weight: 600;
						}
					}
				}
			}
		}

		aside.insightsAside {
			width: 24%;

			@media (max-width: 1023px) {
				width: 100%;
				display: flex;
				text-align: center;
				align-items: center;
				height: 220px;
			}

			h3 {
				margin: 0 0 12px;
				font-size: em(18px);
				line-height: 24px;
			}

			p.small {
				font-size: em(13px);
				line-height: 20px;
				margin: 0;
			}

			.allCategories {
				border-bottom: 1px dotted $silver;
				margin-bottom: 28px;
				padding-bottom: 28px;

				@media (max-width: 1023px) {
					width: 50%;
					margin: 0;
					padding: 0;
					border: none;
					border-right: 1px dotted $silver;
					padding: 0 20px;
				}

				a {
					&, &:link, &:visited {
						display: block;
						font-size: em(13px);
						font-weight: 500;
						margin: 15px 0;

						svg {
							width: 10px;
							margin: 0 0 -3px 2px;
						}
					}
					&:hover, &:active, &:visited {
				    svg {
							margin: 0 0 -3px 4px;
				      g {
				        fill: $linkHover;
				      }
				    }
				  }
				}
			}

			.share {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;

				@media (max-width: 1023px) {
					display: none;
				}

				h3 {
					width: 100%;
					margin-bottom: 10px;
				}
				button {
					margin-right: 10px;
				}
			}

			.newsletter {
				border-bottom: 1px dotted $silver;
				margin-bottom: 28px;
				padding-bottom: 28px;

				@media (max-width: 1023px) {
					padding: 0 20px;
					width: 50%;
					border: none;
				}
			}
		}
	}
}
