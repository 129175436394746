main.difference {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0 0;

	section.banner {
	  height: 450px;
	  width: 100vw;
		position: relative;
		margin: 0;

		@media (max-width: 767px) {
			height: 340px;
		}

		.bannerGradient {
			background: linear-gradient(0deg, rgba(black,.67) 0%, rgba(black,0) 100%);
			height: 100%;
			display: flex;
			align-items: flex-end;
			padding: 0 5vw;

			@media (min-width: 1025px) {
				display: grid;
				grid-template-columns: 1fr 1080px 1fr;
				align-content: end;
				padding: 0;
			}

			@media (max-width: 767px) {
				align-items: center;
				justify-content: center;
				background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,.5) 100%);
			}

			.sectionTitle {
				grid-column: 2 / span 1;
				max-width: 700px;
				margin: 0 auto 40px auto;
				text-align: center;

				h5 {
					color: $mint;
					@media (max-width: 767px) {
 						margin-top: 20px;
 					}
				}

				h1 {
					color: white;
					margin: 0 0 18px;
					text-shadow: 0 2px 10px rgba(black, 0.15),
											 0 1px 2px rgba(black, 0.33);

					@media (max-width: 767px) {
 						margin: 0;
 					}
				}
				.teaser {
					margin: 0 0 24px;
					color: white;
				}
			}
		}
	}

	section.lpIntro {
		width: 90vw;
		margin: 40px 5vw 0 5vw;

		.intro p {
			margin: 0 auto 24px auto;
			max-width: 1280px;
			padding: 0 0 40px;
			text-align: center;
			border-bottom: 1px solid $lightsilver;
		}
	}

	section.imageBlock {
		width: 100vw;
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
		max-width: 1440px;
		margin: 40px auto 0 auto;

		.featureRow {
			display: flex;
			margin-bottom: 50px;
			align-items: center;
			justify-content: center;

			&.left {
				flex-flow: row-reverse;

				@media (max-width: 768px) {
					width: 88vw;
					margin: 0 6vw;
					flex-direction: column;
				}
			}

			@media (max-width: 768px) {
				width: 88vw;
				margin: 0 6vw;
				flex-direction: column;
			}

			figure {
				width: 50%;
				margin: 0;
				overflow: hidden;

				@media (min-width: 1440px) {
					width: 640px;
					align-self: flex-start;
				}

				@media (max-width: 1024px) {
					height: 400px;
				}

				@media (max-width: 768px) {
					width: 100%;
					margin-bottom: 20px;
					height: auto;
					order: 1;
				}

				img {}
			}

			.featureText {
				width: 40%;
		    padding: 0 5%;

				@media (max-width: 1280px) {
					width: 40%;
					padding: 0 5%;
				}

				@media (max-width: 768px) {
					width: 100%;
					margin-bottom: 50px;
					order: 2;
				}

				a.button, p.button a {
					&, &:link, &:visited {
						font-size: 13px;
						font-weight: 500;
						display: inline-block;
						padding: 6px 18px 7px 18px;
						border-radius: 5em;
						background: $link;
						color: white;
						margin-top: 10px;
					}
					&:hover, &:active, &:focus {
						background: $linkHover;
					}
				}
			}
		}
	}

	section.fullWidthQuote {
		background: $teal;
		width: 88vw;
		padding: 60px 6vw;

		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");

		h2, p.attribution {
			color: white;
			max-width: 1140px;
			margin: 0 auto;
			text-align: center;
		}

		h2 {
			margin-bottom: 8px;
		}

		p.attribution {}

	}

	section.featuresGrid {
		width: 88vw;
		padding: 40px 6vw;
		background: $black;

		.featuresBlocks {
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.featuresBlock {
				width: calc(33% - 72px);
				color: white;
				padding: 36px;

				@media (max-width: 1023px) {
					width: calc(50% - 40px);
					padding: 20px;
				}

				@media (max-width: 767px) {
					width: 46%;
					padding: 0;
				}

				@media (max-width: 450px) {
					width: 100%;
					padding: 0 0 28px;
				}


				img {
					width: 60px;
					height: 60px;
					margin: 0 0 10px;
				}

				.h4 {
					color: inherit;
					a {
						&, &:link, &:visited {
							color: $lightMint;
							font-weight: 600;
							margin: 0 0 8px;

							&:after {
								content: "";
								display: inline-block;
								background: url("/icons/icon-mint-arrow.svg") no-repeat;
								width: 20px;
								height: 20px;
								margin: 0 0 -7px 0;
								transform: translateX(8px);
								transition: transform .2s ease;
							}
						}
						&:hover, &:active, &:focus {
							color: $mint;
							cursor: pointer;

							&:after {
								transform: translateX(14px);
							}
						}
					}
				}

				p {
					font-size: $mobileTextSize;
					line-height: $mobileLineHeight;
					margin-bottom: 0;
				}

				&:hover {
					z-index: 2;
					box-shadow: 0px 3px 60px rgba(black,.66),
											0px 1px 3px rgba(black,.33);

					h4 a {
						color: $mint;
						cursor: pointer;
					}
				}

			}
		}

	}

	section.photoCta {
		width: 100vw;
		height: 50vh;
		position: relative;
		margin: 0;

		@media (max-width: 767px) {
			height: 350px;
		}

		picture {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

		.photoCtaContent {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			text-align: center;
			width: 88vw;
			padding: 0 6vw;

			h4 {
				font-family: $headerFont;
			  color: white;
				font-size: 40px;
				font-weight: 700;
				margin: 0 0 16px;
				transition: all .2s ease-out;

				text-shadow: 0 2px 10px rgba(black, 0.15),
										 0 1px 2px rgba(black, 0.33);

				max-width: 680px;
				margin: 0 auto;
				line-height: 1.2;

				@media (max-width: 767px) {
					font-size: 31px;
				}
			}

			a.button, p.button a {
				&, &:link, &:visited {
					font-size: 13px;
					font-weight: 500;
					display: inline-block;
					padding: 6px 18px 7px 18px;
					border-radius: 5em;
					background: $link;
					color: white;
					margin-top: 24px;
				}
				&:hover, &:active, &:focus {
					background: $linkHover;
				}
			}
		}
	}

	section.impactArticles {
		width: 88vw;
		padding: 0 6vw;
		margin: 0 0 40px;

		.introBlock {
			max-width: 740px;
			margin: 60px auto;
			text-align: center;
		}

		.impactGrid {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			margin: 24px auto;
			max-width: 1180px;

			a.lpCard {
				width: 100%;
				width: calc(33.333% - 48px);
				background: white;
				display: flex;
				flex-flow: column;
				padding: 24px;
				justify-content: flex-start;
				position: relative;

				transition: all 0.2s ease-out;
				box-shadow: 0px 2px 44px rgba($black, 0),
										0px 1px 3px rgba($black, 0);

				@media screen and (max-width: 1024px) {
					width: calc(33.333% - 40px);
					padding: 20px;
				}

				@media screen and (max-width: 767px) {
					width: calc(50% - 24px);
					padding: 12px;
				}

				p.h5 {
					margin: 0 0 8px;
					color: $tan;
				}

				.image {
					margin: 0;
				  position: relative;
					height: 150px;
					overflow: hidden;

					img {
						width: 100%;
						object-fit: cover;
						min-height: 120px;

					}
				}

				.h3 {
					color: $black;
					transition: all .2s ease-out;
					margin: 0 0 8px;
					padding: 12px 60px 0 0;
					line-height: 1.5;
					font-family: $bodyFont;
					font-weight: 500;
					position: relative;
					display: flex;
					align-items: center;

					@media screen and (max-width: 1024px) {
						padding: 12px 0 0 0;
					}

					svg {
						width: 20px;
						position: absolute;
						right: 0;
						transition: all .2s ease-out;
						fill: $silver;

						@media screen and (max-width: 1024px) {
							display: none;
						}
					}
				}

				p.articleDesc {
					color: $textColor;
					font-size: em(15px);
					line-height: 23px;
					margin: 0;

					@media screen and (max-width: 767px) {
						display: none;
					}
				}

				&:hover, &:active, &:focus {
					cursor: pointer;
					z-index: 2;
					box-shadow: 0px 3px 60px rgba($black,.25),
											0px 1px 2px rgba($black,.05);

					.h3 {
						color: $teal;

						svg {
							fill: $teal;
						}
					}
				}

			}

		}
	}

}
