main.presentations-listing {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px auto 0;

	section.presentations {
		display: flex;
		flex-flow: column wrap;
		width: 90vw;
		margin: 24px auto 32px;
		padding: 0 5vw;
		max-width: 1212px;
		justify-content: space-between;

		.presentation {
			display: flex;
			align-items: flex-start;
			flex-flow: row wrap;

			.pCard {
				width: calc(25% - 72px);
				display: flex;
				flex-flow: column wrap;
				justify-content: space-between;
				padding: 36px;

				@media (max-width: 1024px) {
					width: calc(33.333% - 60px);
					padding: 30px;
				}

				@media (max-width: 768px) {
					width: calc(50% - 60px);
					padding: 30px;
				}

				@media (max-width: 450px) {
					width: calc(100% - 50px);
					padding: 25px;
				}

				&:hover {
					z-index: 2;
					box-shadow: 0px 3px 60px rgba($black,.25),
											0px 1px 2px rgba($black,.05);

					@media (max-width: 768px) {
						box-shadow: none;
					}
				}

				.pText {
					width: 100%;
					padding-top: 2px;

					.h5 {
						color: $tan;
						font-weight: 500;
						line-height: 1.2;
						margin-bottom: 5px;
					}

					.h4 {
						margin: 0 0 10px;
						font-weight: 600;
						color: $black;
					}

					p {
						line-height: 1.5;
						margin-bottom: 10px;
					}

					span.time {
						display: block;
						border-top: 1px solid $lightsilver;
						border-bottom: 1px solid $lightsilver;
						padding: 4px 0;
						margin: 0 0 10px;
					}
				}

				a.smButton {
					text-align: center;
				}
			}
		}
	}
}
