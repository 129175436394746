main.whitepaper {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0;

	article.wpLayout {
		display: flex;
		padding: 0 5vw;
		max-width: 1140px;
		margin: 0 auto;
		margin-top: 60px;
		justify-content: space-between;

		@media (max-width: 767px) {
			flex-wrap: wrap;
			margin-top: 40px;
		}

		h3 {
			width: 100%;
			border-bottom: 1px solid $lightsilver;
			position: relative;
			margin: 0 0 40px;
			padding-bottom: 6px;
		}

		.wpTitle {
			width: 47%;

			@media (max-width: 767px) {
				width: 100%;
				margin-bottom: 40px;
			}

			.wpCard {
				width: 100%;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;

				figure {
					width: 36%;
					margin: 0;
				}

				.wpText {
					width: 60%;
					padding-top: 4px;

					h4 {
						margin: 0 0 12px;
						font-weight: 600;
					}
					p {
						line-height: 1.5;
						margin-bottom: 12px;
					}
					span.time {
						font-family: $bodyFont;
						font-size: em(12px);
						font-weight: 400;
						text-transform: uppercase;
						letter-spacing: 1px;
						margin: 0;
						color: $textColor;
					}
				}

			}
		}

		.wpDownload {
			width: 47%;

			@media (max-width: 767px) {
				width: 100%;
			}

		}
	}
}
