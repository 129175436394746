header.desktop {
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	position: fixed;
	top: 0;
	background: white;
	z-index: 5;
	height: 100px;
	box-shadow: 0 1px 1px rgba($black,.1),
							0 2px 6px rgba($teal,.05);

	@media (max-width: 1024px) {
		display: none;
	}

	.logo {
		align-self: center;
		padding: 4px 0 0 5%;

    img {
      height: 70px;
    }
	}

	nav {
		align-self: center;

		@media (min-width: 1240px) {
			padding-left: 2vw;
		}

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;

      li {
        display: inline-block;
        margin: 0;

				&:nth-child(4) {
					margin-right: 10px;
				}

        img {
          width: 10px;
        }

        a {
          &, &:link, &:visited {
						display: block;
						padding: 22px 14px;
						color: $black;
						font-size: 1em; //17px;

						@media (max-width: 1140px) {
							padding: 22px 6px;
							font-size: .94em; //16px;
						}
					}

          &:hover, &:active, &:focus {
            color: $teal;
          }
        }
      }
    }
  }

  /* dropdown menu styles */

	.dropdownContainer {
		display: none;
		position: absolute;
		margin-left: -16px;
		top: 56px;
		background: white;
		width: 460px;
		border-radius: 7px;
		box-shadow: 0 8px 4px -4px rgba($black, .15),
								0 1px 4px 0 rgba($teal, .2),
								0 5px 30px 0 rgba($black, .1);

		@media (max-width: 1360px) {
			width: 400px;
		}

		@media (max-width: 1280px) {
			width: 280px;
		}

	  ul {
	    padding: 36px 18px 32px;
			column-count: 2;
			column-gap: 10px;

			@media (max-width: 1280px) {
				padding: 30px 16px 28px;
				column-gap: 8px;
			}

	    li {
	      display: list-item;
	      position: relative;
	      margin: 0 0 8px;
	      height: auto;
				display: inline-block;
				width: 100%;

        img {
					display: none;
        }

				.dropdownAnchor {
					display: none;
				}

	      a {
	        &, &:link, &:visited {
						color: $black;
						font-size: .94em; //16px;
						line-height: 1.375;
						padding: 6px 12px;
						border-radius: 4px;

						@media (max-width: 1280px) {
							font-size: .875em; //15px;
							padding: 4px 12px;
						}
					}

	        &:hover, &:active, &:focus {
	          color: white;
						background: $teal;
	        }
	      }
	    }
	  }

		.dropdownAnchor {
			width: 100%;
			border-top: 1px solid $silver;
			text-align: center;

			a {
				&, &:link, &:visited {
					padding: 14px 14px 15px;
					color: $link;
					font-size: .94em; //16px;
				}

				&:hover, &:active, &:focus {
					color: white;
					background: $teal;
					border-bottom-right-radius: 6px;
					border-bottom-left-radius: 6px;
				}

				@media (max-width: 1280px) {
					font-size: .875em; //15px;
				}
			}
		}
	}

  nav ul li:hover > .dropdownContainer {
    display: inherit;
    transform: translateY(0);
    animation: dropdown .3s ease;
  }

	.navExtras {
		align-self: center;
		margin-right: calc(5% - 10px);
		display: flex;
		align-items: center;

		.clientPortalBtn, .clientPaymentBtn  {
			font-weight: 500;
			text-transform: uppercase;
			text-align: center;
			font-size: .7em;
			line-height: 1.2;
			margin-right: 1em;

			span {
				letter-spacing: .5px;
			}

			a {
				border: 1px solid $silver;
				border-radius: 10em;
				padding: .25em 1.25em;
				display: block;

				&, &:link, &:visited {
					color: $gray;
				}
				&:hover, &:active, &:focus {
					color: $teal;
					border: 1px solid $teal;
				}
			}
		}

		button.openBtn {
		  cursor: pointer;
		  display: block;
		  border: 1px solid rgba($teal,0);
		  border-radius: 50px;
		  width: 50px;
		  height: 50px;
		  display: block;
		  background: white;

			img, svg { pointer-events: none; }
		  svg {
		    circle {
		      transition: all .2s ease-out;
		    }
		    path {
		      transition: all .2s ease-out;
		    }
		  }

		  &:hover {
		    svg {
		      circle {
		        stroke: $teal;
		      }
		      path {
		        fill: $teal;
		      }
		    }
			}

		  &:focus, &:active {
		    outline: none;
		    border: 1px solid rgba($teal,.25);
		  }
		}
	}
}

@keyframes dropdown {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}


header.small {
	height: 60px;

	@media (max-width: 1024px) {
		height: auto;
	}

	.logo {
		img {
			height: 40px;
		}
	}
}

header,
div,
nav,
a,
svg,
img {
	transition: all .2s ease;
}

// Mobile menu

header#mobile {
	display: flex;
	justify-content: space-between;
	align-items: center;
  width: 90%;
	position: absolute;
	z-index: 10;
	padding: 0 5%;
	height: 84px;
  border-bottom: 1px solid $silver;
	background: white;
	display: none;

	@media (max-width: 1024px) {
		display: inherit;
	}

	.modal-open & { display: none; }

	.logo {
		align-self: center;
		flex-basis: 1;

		img {
			height: 50px;
		}
	}

	.menuIcon {
		width: 36px;
		display: flex;
		justify-content: space-between;

		button.menu {
      display: flex;
      justify-content: space-between;
			align-items: center;

			flex-basis: 36px;
		  width: 36px;
		  height: 30px;
		  z-index: 9;
			cursor: pointer;

			background: none;
			outline: none;
			border: 0px;
			padding: 0;


			> span {
				pointer-events: none;
			}

      .strokes {
				width: 100%;
				height: 100%;
				z-index: 8;

        .stroke {
          display: block;
          height: 1px;
          margin: 1px 0 11px 0;
          background: $gray;
          transition:  transform 0.3s, opacity 0.1s;
        }
      }
		}

		// button.menu div.strokes {
		//   height: 1px;
		//   margin: 1px 0 11px 0;
		//   background: $gray;
		//   transition:  transform 0.3s, opacity 0.1s;
		// }
	}
}

.hide {
  opacity: 0;
  transform:  translateX(-42px);
}
.animate0 {
  transform: rotate(45deg) translateY(17px);
}
.animate2 {
  transform: rotate(-45deg) translateY(-17px);
}

body.modal-open {
	overflow: hidden;
	position: fixed;
}

.fullscreenmenu {
  background-color: $silver;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  position: fixed;
  opacity: 0;
  transition: background 0.5s ease-in-out, opacity 0.5s;
	z-index: -2;

	.menuContainer {
		//display: none;
    display: inherit;
    opacity: 1;
    transform: translateY(0);
    animation: menufade .25s linear;
	}

	.close-menu {
		position: absolute;
		top: 24px;
		right: 4.5%;
		background: white;
		width: 30px;
		height: 30px;
		padding: 8px;
		border-radius: 2em;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all .1s ease-out;
		box-shadow: 0 1px 2px 0 rgba($black, .44);
		box-sizing: content-box;
		outline: none;
		border: 0px;

		img {
			width: 14px;
			height: 14px;
			pointer-events: none;
		}

		&:hover, &:active, &:focus {
			transform: scale(1.025);
		}
		&:active, &:focus {
			outline: rgb(94, 158, 215) auto 5px;
		}
	}
}

.fullscreenmenu.show {
  opacity: 1;
	background-color: rgba(white, .99);
  transition: background 0.5s ease-in-out, visibility 0.5s;
	z-index: 8;

	.menuContainer {
		display: inherit;
		position: absolute;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		.mobileMenuBtns {
			margin: 130px 5% 36px;
			display: flex;
			justify-content: space-between;

			a.buttonMobile {
        display: flex;
        align-items: center;
				justify-content: left;

				position: relative;
        padding-left: 16px;
        width: calc(32% - 16px);
				height: 40px;

      	background: $teal;
      	border-radius: 20px;

      	color: white;
      	font-size: 13px;
      	font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 1px;

        svg {
          transform: scale(.75);
          position: absolute;
          right: 12px;

          path {
            fill: white;
          }
        }

        &:nth-child(2) svg {
          top: 14px;
        }
      }
		}

		.mobileNavExtras {
			margin: 0 5% 30px;
			display: flex;
			justify-content: center;
			border-top: 1px solid $lightsilver;
			border-bottom: 1px solid $lightsilver;
			padding: .5em 0;
		}

		ul.mobileMenu {
			list-style: none;
			padding: 0;
			margin: 0 5% 60px;
			width: 90%;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 20px;

			@media (max-width: 450px) {
				grid-template-columns: repeat(2, 1fr);
			}

			li.parent {
				font-family: $headerFont;
				font-size: 21px;
				font-style: normal;
				font-weight: 700;

				a {
					&, &:link, &:visited {
						color: $link;
					}

					&:hover, &:active, &:focus {
						color: $linkHover;
					}
				}

				ul.mobileChildren {
					list-style: none;
					padding: 0;
					margin: 16px 0;

					li.child {
						display: list-item;
						font-family: $bodyFont;
						line-height: 1.4;
			      position: relative;
						font-weight: 400;
			      margin: 0;
			      height: auto;
						display: inline-block;
						width: 100%;
						font-size: 15px;
						border-bottom: 1px solid lighten($silver, 10%);

						a {
							&, &:link, &:visited {
								visibility: inherit;
								color: $gray;
								display: block;
								padding: 9px 0 11px;
							}
							&:hover, &:active, &:focus {
								color: $teal;
							}
						}
					}
				}
			}
		}
	}
}
