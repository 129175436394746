main.impact {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0 80px;

	// Mobile layout will be served if browser doesn't support grid
	@media (min-width: 1025px) {
		margin: 100px 0 80px;
		display: grid;
		grid-template-columns: 1fr 90vw 1fr;
		grid-template-rows: 580px 1fr;
		grid-template-areas:
			"banner banner 	banner"
			". 			content	.";
	}

	@media (min-width: 1200px) {
		grid-template-columns: 1fr 1080px 1fr;
	}

	section.banner {
		grid-area: banner;
	  height: 580px;
	  width: 100vw;
		position: relative;
		background-size: cover;
	  background-position: center center;
		background-repeat: none;
		margin: 0;

		@media (max-width: 767px) {
			height: 340px;
		}

		.bannerGradient {
			background: linear-gradient(0deg, rgba(black,.67) 0%, rgba(black,0) 100%);
			height: 100%;
			display: flex;
			align-items: flex-end;
			padding: 0 5vw;

			@media (min-width: 1025px) {
				display: grid;
				grid-template-columns: 1fr 1080px 1fr;
				align-content: end;
				padding: 0;
			}

			@media (max-width: 767px) {
				align-items: center;
				justify-content: center;
				background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,.5) 100%);
			}

			.sectionTitle {
				grid-column: 2 / span 1;
				max-width: 700px;
				margin: 0 auto 40px auto;
				text-align: center;

				.h5 {
					color: $mint;
					@media (max-width: 767px) {
 						margin-top: 20px;
 					}
				}

				h1 {
					color: white;
					margin: 0 0 18px;
					text-shadow: 0 2px 10px rgba(black, 0.15),
											 0 1px 2px rgba(black, 0.33);

					@media (max-width: 767px) {
 						margin: 0;
 					}
				}
				.teaser {
					margin: 0 0 24px;
					color: white;
				}
			}
		}
	}

	section.contentArea {
		grid-area: content;
		order: 2;
		margin-top: 50px;

		@media (max-width: 1024px) {
			width: 90vw;
			margin: 50px 5vw 0 5vw;
		}

		.intro p {
			margin: 0 auto 24px auto;
			text-align: center;
		}

		.share {
			margin: 0 auto 40px auto;
			max-width: 700px;
			text-align: center;
			border-bottom: 1px solid $silver;
			padding-bottom: 40px;

			button {
				margin: 0 6px;
				display: inline-block;
			}
		}

		h4 {
			font-weight: 600;
			color: $black;
			max-width: 700px;
			margin-right: auto;
			margin-left: auto;
		}

		figure {
			margin: 0 32px 32px 32px !important;

			@media (max-width: 768px) {
				float: none;
				width: 100%;
				margin: 32px auto !important;
			}

	    figcaption {
				margin: 0;
	      font-size: 13px;
	      padding: 8px 0;
	      display: block;
	      color: $textColor;
	      border-bottom: 1px solid $silver;
	    }
	  }

		p {
			max-width: 700px;
			margin-right: auto;
			margin-left: auto;
		}

		ul, ol {
			max-width: 700px;
			margin-right: auto !important;
			margin-left: auto !important;
			padding: 0;

			li {
				margin-left: 32px;
				margin-right: 32px;
			}
		}

		blockquote {
			text-align: center;
			color: $teal;

			p {
				font-size: inherit;
				max-width: inherit;
				width: inherit;
				line-height: inherit;
				margin: inherit;
			}
		}

		.return {
			border-top: 1px solid $silver;
			padding: 30px 0 0 0;
			margin: 60px auto 40px auto;
			max-width: 700px;

			a, a:hover {
				border: none;
			}
		}

	}
}
