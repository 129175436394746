button.sharer {
	background: white;
	border: 1px solid $silver;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	display: flex;
	transition: all .2s ease-out;
	justify-content: space-between;

	svg {
		transform: scale(.8);
		path {
			fill: $black;
		}
	}

	&:hover {
		border: 1px solid $teal;
		cursor: pointer;
		svg {
			path {
				fill: $teal;
			}
		}
	}
}
