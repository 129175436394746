main.eventList {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px auto 0;

	section.eventPosts {
		display: flex;
		flex-flow: row wrap;
		width: 90vw;
		margin: 40px auto 32px;
		padding: 0 5vw;
		max-width: 1140px;
		justify-content: space-between;

		@media (max-width: 1023px) {
			margin-bottom: 0;
		}

		.eventList {
			width: 62%;
			display: flex;
			flex-flow: column wrap;
			margin-bottom: 40px;
			padding-right: 7%;
			border-right: 1px solid $lightsilver;

			@media (max-width: 1023px) {
				width: 100%;
				padding-right: 0;
				margin-bottom: 20px;
				border: none;
			}

			h3 {
				border-bottom: 1px solid $silver;
				padding-bottom: 8px;
				margin: 0 0 16px;
			}

			.eventsUpcoming {
				.eventCard {
					width: 100%;
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					align-items: flex-start;
					padding: 18px;
					transform: translateX(-18px);
					border-bottom: 1px dotted $silver;
					padding-bottom: 18px;

					&:last-child {
						border-bottom: none;
					}

					@media (max-width: 767px) {
						padding: 24px 0 12px;
						transform: translateX(0);
						align-items: flex-start;
					}

					&:hover {
						z-index: 2;
						box-shadow: 0 3px 60px rgba($black,.25), 0 1px 2px rgba($black,.05);
					}

					figure {
						width: 25%;
						margin: 0;
						@media (max-width: 540px) {
							width: 36%;
						}
					}

					.eventCardText {
						width: 72%;
						padding-top: 4px;
						@media (max-width: 540px) {
							width: 60%;
						}

						p.date {
							font-family: $bodyFont;
							font-size: em(12px);
							font-weight: 500;
							text-transform: uppercase;
							letter-spacing: 1px;
							margin: 0;
							color: $tan;
						}

						h4 {
							margin: 0;
						}
					}

				}
			}

			.eventsPrevious {
 				position: relative;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin: 32px 0 0 0;
				padding: 60px 0 0 0;

				h3 {
					position: absolute;
					width: 100%;
					top: 0;
				}

				.eventCard {
					width: calc(100% - 36px);
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					padding: 0 18px;
					transform: translateX(-18px);
					padding-bottom: 18px;

					@media (max-width: 767px) {
						padding: 24px 0 12px;
						transform: translateX(0);
						align-items: flex-start;
					}

					.eventCardText {

						p.date {
							font-family: $bodyFont;
							font-size: em(12px);
							font-weight: 500;
							text-transform: uppercase;
							letter-spacing: 1px;
							margin: 0;
							color: $tan;
						}

						h4 {
							font-size: em(16px);
							margin: 0;
						}
					}
				}
			}
		}
	}
}
