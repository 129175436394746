main.bioSimple {
	width: 100%;
	display: flex;
	margin: 180px auto 80px auto;
	justify-content: space-between;
	max-width: 960px;

	@media (max-width: 1023px) {
		max-width: 720px;
	}

	@media (max-width: 767px) {
		flex-flow: column wrap;
		width: 90vw;
		margin: 180px 5vw 80px 5vw;
	}

	section.bioLeft {
		width: 33%;
		text-align: right;

		@media (max-width: 767px) {
			width: 100%;
			text-align: center;
			margin-bottom: 32px;
		}

		.bioPhoto img {
			border-radius: 400px;

			@media (max-width: 767px) {
				max-width: 280px;
			}
		}
	}

	section.bioRight {
		width: 60%;

		@media (max-width: 767px) {
			width: 100%;
			text-align: center;
		}

		.bioTitleSimple {

			p.intro {
				margin-bottom: 12px;
			}

			a.uppercase {
				margin: 0;
				display: flex;
				text-transform: uppercase;
				font-weight: 500;
				font-size: em(14px);

				@media (max-width: 767px) {
					justify-content: center;
				}

				svg {
					margin-right: 4px;
					transform: scale(.75);
				}
			}
		}
		.backgroundSimple {
			border-top: 1px solid $silver;
			padding-top: 40px;
			margin: 40px 0;
			h4 {
				font-weight: 600;
			}
		}

	}


}
