main.portal {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px auto 0 auto;

	section.portalTerms {
		display: flex;
		flex-flow: row wrap;
		width: 90vw;
		margin: 32px auto;
		padding: 0 5vw;
		max-width: 1140px;
		justify-content: space-between;

		@media (max-width: 1024px) {
			margin: 24px 0;
		}

		.termsFrame {
			width: calc(100% - 80px);
			height: 420px;
			overflow: scroll;
			border: 1px solid $silver;
			padding: 40px;
		}

		form {
			width: 100%;
			max-width: 1140px;
			margin: 24px auto;

			input {
				background: $link;
				border: none;
				font-weight: 400;
				font-size: $mobileTextSize;
				font-family: $bodyFont;
				line-height: $mobileLineHeight;
				color: white;
				background: $link;
				padding: 10px 24px;
				margin: 8px 0 24px 0;
				min-width: 120px;
				border-radius: 40px;
				transition: all .2s ease-out;
				cursor: pointer;
				box-shadow: 0px 0px 0px 1px rgba($silver,0),
										0px 0px 0px 2px rgba(white,0);

				&:hover, &:active, &:focus {
					background: $linkHover;
					box-shadow: 0px 0px 0px 1px rgba($silver,.5),
											0px 0px 0px 2px rgba(white,.5);
				}
			}
		}
	}
}
