.sitemap {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li.h4 {
      margin: 0 0 16px;
      line-height: 48px;

      a {
        margin: 0 0 36px;
      }

      ul {
        list-style: square;
        margin: 10px;
        padding: 10px;

        li {
          margin: 0 0 12px;
          font-size: .75em;
          line-height: $mobileLineHeight;

          a {
            color: $teal;
          }

          ul {
            li {
              font-size: .875em;
            }
          }
        }
      }
    }
  }
}
