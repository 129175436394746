main.whitePapersList {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px auto 40px;

	@media (max-width: 1024px) {
		margin: 80px 0 24px 0;
	}

	section.allWhitePapers {
		display: flex;
		flex-flow: column wrap;
		width: 90vw;
		margin: 48px auto 32px;
		padding: 0 5vw;
		max-width: 1188px;
		justify-content: space-between;

		@media (max-width: 1280px) {
			max-width: auto;
			width: calc(90vw + 48px);
			padding: 0 calc(5vw - 24px);
		}

		@media (max-width: 1024px) {
			margin: 24px auto;
			width: 90vw;
			padding: 0 5vw;
		}

		h3 {
			width: calc(100% - 48px);
			border-bottom: 1px solid $lightsilver;
			position: relative;
			margin: 0 auto 40px;
			padding-bottom: 6px;

			@media (max-width: 1023px) {
				width: 100%;
			}

		}

		span.time {
			font-family: $bodyFont;
			font-size: em(12px);
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin: 0;
			color: $textColor;
		}

		.premiumResources {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;

			@media (max-width: 1023px) {
				justify-content: space-between;
			}

			a.wpCard {
				&, &:link, &:visited {
					width: calc(25% - 48px);
					display: flex;
					flex-flow: column wrap;
					justify-content: space-between;
					padding: 24px;

					@media (max-width: 1023px) {
						flex-flow: row wrap;
						width: 48%;
						padding: 0;
						margin-bottom: 20px;
					}

					@media (max-width: 620px) {
						width: 100%;
					}
				}

				&:hover, &:active, &:focus {
					z-index: 2;
					box-shadow: 0px 3px 60px rgba($black,.25),
											0px 1px 2px rgba($black,.05);
				}

				figure {
					width: 100%;
					margin: 0 0 8px;

					@media (max-width: 1023px) {
						width: 33%;
						margin: 0 0 8px;
					}
				}

				.wpText {
					width: 100%;
					padding-top: 2px;

					@media (max-width: 1023px) {
						width: 62%;
					}

					h4 {
						margin: 0 0 10px;
						font-weight: 600;
						color: $black;
					}

					p {
						line-height: 1.5;
						margin-bottom: 12px;
					}

				}
			}
		}

		.otherResources {
			margin-top: 40px;

			ul {
				padding: 0;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				width: calc(100% - 48px);
				margin: 0 auto;
				font-size: 1em;

				@media (max-width: 1023px) {
					width: 100%;
				}

				li {
					list-style: none;
					width: 48%;
					display: flex;
					justify-content: space-between;
					margin-bottom: 20px;

					@media (max-width: 1023px) {
						margin-bottom: 16px;
					}

					figure {
						margin: 0;
						width: 12%;
						justify-content: space-between;

						@media (max-width: 1023px) {
							display: none;
						}
					}

					.orText {
						width: 85%;

						@media (max-width: 1023px) {
							width: 100%;
						}

						h4 {
							line-height: 19px;
							margin: 0;
							font-size: em(15px);

					 		a {
								&, &:link, &:visited {
									font-weight: 600;
									color: $black;
								}

								&:hover, &:active, &:focus {
									color: $teal;
								}

								img {
									margin: 0 0 0 4px;
									height: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
}
