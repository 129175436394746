main.stats {
	width: 90vw;
	margin: 100px auto 40px;
	padding: 0 5vw;
	// max-width: 1080px;

	section.articleStats {
		display: flex;
		flex-flow: column;

		@media (min-width: 768px) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-column-gap: 20px;
		}


		div {
			h2 {
				border-bottom: 1px solid $silver;
				padding: 0 0 4px;
				margin: 0 0 20px;

				span {
					font-size: 12px;
					font-family: $bodyFont;
					background: $mint;
					color: $black;
					padding: 2px 8px;
					border-radius: 16px;
					font-weight: 600;
					margin: 0 0 0 4px;
					transform: translateY(-3px);
					display: inline-block;
				}
			}
			span.count {
				font-size: 12px;
				background: $mint;
				color: $black;
				padding: 1px 7px;
				border-radius: 14px;
				font-weight: 600;
			}

			h4 {
				font-size: 15px;
				margin: 4px 0 0;
			}

			p.author {
				margin: 3px 0 12px;
				font-size: 11px;
				letter-spacing: 0;
			}
		}

		div:nth-child(1) {
			grid-column: 1 / span 1;
		}
		div:nth-child(2) {
			grid-column: 2 / span 1;
		}
		div:nth-child(3) {
			grid-column: 3 / span 1;
		}
		div:nth-child(4) {
			grid-column: 4 / span 1;
		}
	}
}
