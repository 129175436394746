/*
essential styles:
these make the slideshow work
*/

#slides {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.slide {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s;
}

.showing {
  opacity: 1;
  z-index: 2;
}

/*
non-essential styles:
*/

li.slide {
  color: white;
  width: 100%;
  height: 100%;

  picture {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .heroContent {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

  	.heroGradient {
  		background: linear-gradient(90deg, rgba(black,.66) 0%, rgba(black,0) 70%);
  		height: 100%;
  		position: relative;

  		@media (max-width: 540px) {
  			background: linear-gradient(90deg, rgba(black,.25) 0%, rgba(black,.25) 100%);
  		}
  	}

  	.heroText {
  		position: absolute;
  		left: 5%;
  		top: 50%;
      max-width: 500px;
  		transform: translateY(-53%);
      display: flex;
      flex-flow: column wrap;

  		@media (max-width: 540px) {
  			width: 100%;
  			left: 0;
        transform: translateY(-60%);
        align-items: center;
  		}

      p.h5 {
        color: $mint;
        font-size: .7em;
        font-weight: normal;
        text-shadow: 0 2px 10px rgba(black, 0.15),
                     0 1px 2px rgba(black, 0.33);

        @media (max-width: 540px) {
          text-align: center;
          background: $black;
          font-size: 0.75em; //12px;
          padding: 4px 8px;
          text-shadow: none;
        }
      }

  		h2 {
  			color: white;
        text-shadow: 0 2px 10px rgba(black, 0.15),
  									 0 1px 2px rgba(black, 0.33);
        margin: 0 0 10px;
        font-size: 3.533em; //60px;
        line-height: 1.05;

        @media (max-width: 767px) {
          font-size: 3em; //48px;
      	}

  			@media (max-width: 540px) {
  				text-align: center;
          width: 90%;
          padding: 0 5%;
  			}

  		}

      p {
        font-size: 1em;

        @media (max-width: 540px) {
          display: none;
        }
      }

  		a {
        &, &:link, &:visited {
          padding: 12px 28px 14px;
          width: auto;
          margin: 16px 10px 0 0;
          max-width: 220px;
          box-shadow: 0px 0px 0px 1px rgba($linkHover,.25),
                      0px 0px 0px 2px rgba(white,0);
        }
        &:hover {
          background: darken($link, 5%);
          transform: translateY(-1px);
          box-shadow: 0px 0px 0px 1px rgba($linkHover,.5),
                      0px 0px 0px 2px rgba(darken($link, 5%),.1);
        }

        &:active, &:focus {
          box-shadow: 0px 0px 0px 2px rgba(darken($link, 5%),1),
                      0px 0px 0px 6px rgba(white,0);
        }

  			@media (max-width: 540px) {
  				text-align: center;
  			}
  		}
  	}
  }
}

.buttons {
  position: absolute;
  right: 5%;
  bottom: 42px;
  z-index: 4;

  @media (max-width: 740px) {
		bottom: 24px;
		left: 5%;
	}

	@media (max-width: 540px) {
		left: calc(50% - 84px);
	}

  .controls {
	  background: white;
	  border: none;
	  cursor: pointer;
	  border: 1px solid #fff;
	  margin: 10px 4px 0 0;
	  width: 48px;
	  height: 48px;
	  border-radius: 48px;
	  text-align: center;
		opacity: 1;
		transition: all .2s ease-out;
		opacity: .95;
    box-shadow: 0 1px 4px 0 rgba($black, .33);

	  &:hover, &:active, &:focus {
		  opacity: 1;
		  transform: scale(1.04);
			box-shadow: 0 2px 4px 0 rgba($black, .66);
		}

		// &:focus {
		//   outline: none;
		// }
	}

}
