section.cardsContainer {
	display: flex;
	flex-flow: column wrap;
  width: 90vw;
  margin: 40px auto 0;
  padding: 0 5vw;
  max-width: 1080px;


	h3 {
		grid-area: category;
		border-bottom: 1px solid $lightsilver;
		position: relative;
    margin: 0 24px;
		padding-bottom: 10px;

		@media screen and (max-width: 1024px) {
			margin: 0 0 10px 0;
		}

		a {
			&, &:link, &:visited {
				position: absolute;
				right: 0;
				font-family: $bodyFont;
				font-size: em(14px);
				font-style: normal;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}
	}
}

.articleCards {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
	margin: 12px auto 0 auto;

	@media (max-width: 1024px) {
		justify-content: space-between;
	}

	.articleCard {
		width: calc(33.333% - 40px);
		background: white;
		display: flex;
		flex-flow: column;
		padding: 20px;
		justify-content: flex-start;
		position: relative;

		transition: all 0.2s ease-out;
		box-shadow: 0px 2px 44px rgba($black, 0),
								0px 1px 3px rgba($black, 0);

		@media (max-width: 1023px) {
			width: calc(33.333% - 20px);
			padding: 0;
			margin-bottom: 20px;
		}

		@media (max-width: 767px) {
			width: 100%;
			flex-flow: row;
			justify-content: space-between;
			padding: 0;
		}

		figure {
			margin: 0;

			a {
				img {
					width: 100%;
				}
			}
			@media (max-width: 767px) {
				width: 48%;
			}

			@media (max-width: 540px) {
				width: 36%;
			}
		}

		.articleCardText {

			@media (max-width: 767px) {
				width: 48%;
			}

			@media (max-width: 540px) {
				width: 60%;
			}

			h4 {
				margin-bottom: 4px;
				padding: 12px 0 0 0;

				@media (max-width: 540px) {
					padding: 0;
				}

				a {
					&, &:link, &:visited {
						font-weight: 600;
						display: block;
						color: $black;
						transition: all .2s ease-out;
						font-family: $bodyFont;
					}

					&:hover, &:active, &:focus {
						color: $teal;
					}
				}
			}
		}

		&:hover, &:active, &:focus {
			z-index: 2;
			box-shadow: 0px 3px 60px rgba($black,.25),
									0px 1px 2px rgba($black,.05);
			@media (max-width: 767px) {
				box-shadow: none;
			}
		}
	}
}
