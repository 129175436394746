section.cta {
	width: 100vw;
	background: $lightsilver;
	padding: 60px 0 48px;
	max-width: 100%;

	@media(max-width: 1200px) {
		width: 90vw;
		padding: 60px 5vw 48px 5vw;
	}

	.wrapper {
		max-width: 1140px;
		margin: 0 auto;
		text-align: center;

		@media(min-width: 500px) {
			display: grid;
			grid-gap: 40px;
			grid-template-columns: 1fr max-content;
			text-align: left;
      align-items: center;
		}
	}

	.content {
		grid-column: 1 / span 1;

		h2, h3 {
			font-size: 1.875em; //30px;

			span.teal {
				color: $teal;
			}
		}
	}

	.actions {
		grid-column: 2 / span 1;

		.button {
			font-size: 0.95em;
		}
	}
}

// article callout class
.article-cta {
	background: $lightsilver;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 1em;

	a {
		&, &:link, &:visited {
			font-weight: 400;
			font-size: $mobileTextSize;
			font-family: $bodyFont;
			line-height: $mobileLineHeight;
			color: white;
			background: $link;
			padding: 10px 24px;
			min-width: 120px;
			text-align: center;
			display: inline-block;
			border-radius: 40px;
			box-shadow: 0px 0px 0px 1px rgba($silver,0),
									0px 0px 0px 2px rgba(white,0);
		}
		&:hover {
			background: darken($link, 5%);
			box-shadow: 0px 0px 0px 1px rgba($silver,.5),
									0px 0px 0px 2px rgba(white,.5);
		}

		&:active, &:focus {
			box-shadow: 0px 0px 0px 8px rgba($silver,1),
									0px 0px 0px 18px rgba(white,0);
		}
	}
}
