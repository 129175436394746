main.people {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0;
	// Mobile layout will be served if browser doesn't support grid
	@media (min-width: 1025px) {
		margin: 100px 0 80px;
		display: grid;
		grid-template-columns: 1fr 24px 980px 24px 1fr;
		grid-template-rows: 250px repeat(auto);
		grid-template-areas:
		"banner banner banner	banner banner"
		". . 	lpIntro . ."
		". . 	search . ."
		". bios	bios bios .";
	}
	@media (min-width: 1200px) {
		grid-template-columns: 1fr 24px 1140px 24px 1fr;
	}

	section.banner {
		grid-area: banner;
	  height: 250px;
	  width: 100vw;
		background: $black;
		position: relative;
		background-size: cover;
	  background-position: center center;
		background-repeat: none;
		margin: 0;

		@media (max-width: 767px) {
			height: 180px;
		}

		picture {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bannerContent {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;

			.bannerGradient {
				background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,0) 100%);
				height: 100%;
				display: flex;
				align-items: flex-end;
				padding: 0 5vw;

				@media (min-width: 1025px) {
					display: grid;
					grid-template-columns: 1fr 980px 1fr;
					align-content: end;
					padding: 0;
				}

				@media (min-width: 1200px) {
					grid-template-columns: 1fr 1140px 1fr;
				}

				@media (max-width: 767px) {
					align-items: center;
					background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,.5) 100%);
				}

				.sectionTitle {
					grid-column: 2 / span 2;

					h1 {
						color: white;
						margin: 0 0 18px;
						text-shadow: 0 2px 10px rgba(black, 0.15),
												 0 1px 2px rgba(black, 0.33);

						@media (max-width: 767px) {
	 						margin: 0;
	 					}
					}

					.h5 {
						color: white;
					}
				}
			}
		}
	}

	section.lpIntro {
		grid-area: lpIntro;
		margin: 50px 25% 0 0;

		@media (max-width: 1024px) {
			width: 90vw;
			margin: 40px 5vw 0 5vw;
		}
	}

	section.bioSearch {
		display: none;

		@supports (display: grid) {
			display: inherit;
			grid-area: search;
			width: 100%;
			margin: 72px 0 0 0;
			display: flex;
			flex-flow: column wrap;
			position: relative;
		}

		@media (max-width: 1024px) {
			width: 90vw;
			margin: 48px 5vw 36px 5vw;
		}

		.autocompleteSearch {
			width: 100%;
			position: relative;

			input[type=text] {
				padding: 20px 30px 20px 66px;
				border: 1px solid $lightsilver;
				width: calc(100% - 96px);
				transition: all .1s ease-out;
				border-radius: 2em;
				box-shadow: 0 1px 2px 0 rgba($black, .1),
										0 4px 24px 0 rgba($teal, .05);

				&::placeholder {
					color: rgba($black, .5);
				}

				&:focus, &:active {
					outline: none;
					border: 1px solid lighten($silver, 12%);
					box-shadow: 0 1px 2px 0 rgba($black, .3),
											0 4px 24px 0 rgba($teal, .1);
				}
			}

			img {
		    position: absolute;
		    left: 15px;
		    top: 19px;
		  }
		}

		.autocompleteResults {
			width: 100%;
			position: absolute;
			top: 78px;
			z-index: 3;
			background: white;
			box-shadow: 0 1px 3px 0 rgba($black, .3),
									0 4px 50px 0 rgba($black, .3),
									0 1px 2px 0 rgba($teal, .4);

			ul {
				margin: 0;
				padding: 0;

				li {
					list-style: none;
					margin: 0;
					border-bottom: 1px solid $silver;
					position: relative;
					height: 70px;

					a {
						&, &:link, &:visited {
							padding: 18px 24px 0 100px;
							height: calc(100% - 18px);
							display: block;
							color: $black;
						}

						&:hover, &:active, &:focus {
							background: lighten($silver, 12%);
							color: $teal;
						}
					}

					div {
						position: absolute;
						left: 0;
						top: 0;
					}
				}
			}
		}

		.autocompleteData {
			display: none;
		}

	}

	section.bioListing {
		grid-area: bios;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		margin: 40px auto 0 auto;

		@media screen and (max-width: 1024px) {
			width: calc(90% + 40px);
		}

		@media screen and (max-width: 767px) {
			width: calc(90% + 24px);
			margin: 24px auto 0 auto;
		}

		a.bioThumb {
			background: white;
			width: calc(33.333% - 48px);
			display: flex;
			flex-flow: column;
			padding: 24px;
			justify-content: flex-start;
			position: relative;

			transition: all 0.2s ease-out;
			box-shadow: 0px 2px 44px rgba($black, 0),
									0px 1px 3px rgba($black, 0);

			@media screen and (max-width: 1024px) {
				width: calc(33.333% - 40px);
				padding: 20px;
			}

			@media screen and (max-width: 767px) {
				width: calc(50% - 24px);
				padding: 12px;
			}

			.photo {
				width: 100%;

				// IE11 Support
				height: 400px;
				@supports (display: grid) {
					height: auto;
				}

				picture {
		      position: relative;
		      width: 100%;
		      height: 100%;
		      overflow: hidden;
		      img {
		        width: 100%;
		        height: 100%;
		        object-fit: cover;
		      }
		    }
			}

			.text {
				width: 100%;
				margin-right: 30px;
				position: relative;

				h3 {
					margin: 12px 0 2px 0;
					width: calc(100% - 40px);
					color: $black;
					font-family: $bodyFont;
					font-weight: 600;
				}

				p {
					margin: 0;
					color: $gray;
					font-size: em(16px);
					line-height: 24px;

					@media screen and (max-width: 768px) {
						font-size: em(14px);
						line-height: 22px;
					}

					&.email {
						border-top: 1px solid $silver;
						margin-top: 12px;
						padding-top: 10px;
						color: $teal;

						@media screen and (max-width: 767px) {
							display: none;
						}
					}
				}
			}

			.eye {
				opacity: 0;
			}
			@supports (display:grid) {
				.eye {
					opacity: inherit; // RESET IE11 FIX
				}
			}

			.eye {
				position: absolute;
				right: 0;
				top: 18px;

				@media screen and (max-width: 767px) {
					display: none;
				}

				svg {
					fill: lighten($silver, 10%);
					width: 20px;
					transition: all 0.2s ease-out;
				}
			}

			&:hover, &:active, &:focus {
				cursor: pointer;
				z-index: 2;
				box-shadow: 0px 3px 60px rgba($black,.25),
										0px 1px 2px rgba($black,.05);


				h3 {
					color: $teal;
				}

				.eye svg {
					fill: $teal;
				}
			}
		}
	}
}
