// mark was modified to represent the inline "focused text area" style
mark {
	display: block;
	background: lighten($silver, 12.5%);
	color: $textColor;
	padding: 18px 32px;
	margin: 36px 0;
	font-size: 1em;
	line-height: 28px;
	font-style: normal;
	border-left: 4px solid $teal;

	// @media (max-width: 767px) {
	// 	font-size: $mobileTextSize;
	// }
}
