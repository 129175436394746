main.home {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 0 auto;

	section.hero {
		width: 100vw;
	  height: 75vh;
	  margin-top: 100px;
		position: relative;
		max-width: 100%;

	  @media (max-width: 1024px) {
	    margin-top: 80px;
	  }

	  @media (max-width: 767px) {
	    height: 480px;
	  }
	}

	section.homeInsights {
	  display: flex;
	  flex-flow: row wrap;
		width: 88vw;
	  margin: 80px auto 0 auto;
		padding: 0 6vw;
		justify-content: space-between;
		align-content: flex-start;
		align-items: flex-start;

	  .feature {
	    width: 60%;
	    margin: 20px 0;

			figure {
				margin: 0;
				padding: 0;
			}
	  }

		.trending {
			margin: 62px 0 0 0;
			background: $black;
			width: calc(30% - 80px);
			padding: 36px 40px 30px 40px;
			position: relative;

			.h3 {
				color: white;
				margin-bottom: 24px;
			}

			.h4 {
				a {
					&, &:link, &:visited {
						display: block;
						color: $mint;
						font-weight: normal;
						border-bottom: 1px solid rgba(white, .1);
						padding-bottom: 20px;
						margin-bottom: 20px;
					}
					&:hover, &:active, &:focus {
						color: $lightMint;
					}
				}
				&:last-child a {
					border: none;
					padding-bottom: 0;
					margin-bottom: 4px;
				}
			}

		}

		.latest {
			display: none;
		}

		a.rightArrowTeal {
			display: block;
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			margin: 60px 0 0;
			padding: 20px 0 0;
			line-height: 1.5;
			border-top: 1px dotted $silver;
		}
	}

	@supports (display: grid) {

	  section.homeInsights {
			width: 90vw;
	    margin: 80px auto;
	    max-width: 1360px;
			padding: 0;
	    display: grid;
	    grid-template-columns: 67fr 28fr;
	    grid-template-rows: auto;
	    grid-template-areas:
	      "feature  trending"
	      "feature  latest";
	    grid-gap: 60px;

			@media (max-width: 1511px) {
				margin: 80px 5vw;
			}

	    @media (max-width: 768px) {
	      grid-template-columns: 1fr;
	      grid-template-areas:
	        "feature"
	        "trending";
	    }

	    .feature {
	      grid-area: feature;
	      margin: 0;
				width: auto;

				figure.zoom {
					position: relative;
			    max-width: 100%;
			    margin: 0;
					padding: 0;
			    overflow: hidden;

					img {
						display: block;
			      width: 100%;
			      height: auto;
			      transition: all 0.3s ease;
						overflow: hidden;
					}

				}

				.featureContainer {

					&:hover figure.zoom img {
						transform: scale(1.025);
					}

					&:hover .h2 a {
						&, &:link, &:visited {
							color: $teal;
						}
					}

		      .h2 a {
						&, &:link, &:visited {
							color: inherit;
						}
						&:hover, &:active, &:focus {
							color: $link;
						}
		      }
				}


	      .h3 {
	        border-bottom: 1px solid $silver;
	        padding-bottom: 12px;
					margin: 0 0 32px 0;
					a {
						&, &:link, &:visited {
							color: inherit;
						}
						&:hover, &:active, &:focus {
							color: $link;
						}
					}
	      }

	      img {
	        margin-bottom: 40px;
	        @media (max-width: 1024px) {
	          @supports not (justify-content: space-evenly) {
	            opacity: none;
	          }
	        }
	      }

	      .h5 {
					color: $tan;
					a {
						&, &:link, &:visited {
							color: inherit;
						}
						&:hover, &:active, &:focus {
							color: $link;
						}
					}
	      }

	      .h2, .h3, .h5, p {
	        max-width: 860px;
	      }

	      a.rightArrowTeal {
	        display: block;
	        font-size: 20px;
	      	font-style: normal;
	      	font-weight: 500;
	      	margin: 60px 0 0;
	        padding: 20px 0 0;
	      	line-height: 1.5;
	        border-top: 1px dotted $silver;
	      }
	    }

	    .trending {
	      margin: 0;
	      grid-area: trending;
	      background: $black;
	      width: 125%;
	      justify-self: end;
	      padding: 36px 40px 30px 40px;
				position: relative;

	      @media (max-width: 768px) {
	        background: $black;
	        padding: 40px 32px;
	        width: calc(100% - 64px);
	        justify-self: center;
	      }

	      .h3 {
	        color: white;
	        margin-bottom: 24px;
	      }

	      .h4 {
					a {
						&, &:link, &:visited {
							display: block;
							color: $mint;
							font-weight: normal;
							border-bottom: 1px solid rgba(white, .1);
							padding-bottom: 20px;
							margin-bottom: 20px;
						}
						&:hover, &:active, &:focus {
							color: $lightMint;
						}
					}
					&:last-child a {
						border: none;
						padding-bottom: 0;
						margin-bottom: 4px;
					}
	      }

	    }

	    .latest {
				display: inherit;
	      margin: 0;
	      grid-area: latest;

				@media (max-width: 768px) {
					display: none;
				}

				.h3 {
					border-bottom: 1px solid $silver;
					padding-bottom: 12px;
					margin: 0 0 32px 0;
				}
				ul {
					list-style: none;
					padding: 0;
					margin: 0;
					font-size: 1em;

					li {
						margin-bottom: 26px;
						display: grid;
						grid-template-columns: 75px 1fr;
						grid-column-gap: 20px;
						border-bottom: 1px solid rgba($silver, .5);
						padding-bottom: 14px;

						@media (max-width: 1280px) {
							display: flex;
						}

						&:last-child {
							border: none;
						}

						img {
							grid-column: 1 / span 1;
							min-height: 75px;
							object-fit: cover;

							@media (max-width: 1280px) {
								display: none;
							}
						}

						.trendingText {
							grid-column: 2 / span 1;

							.h5 {
								color: $tan;
								font-weight: 500;
								a {
									&, &:link, &:visited {
										color: inherit;
									}
									&:hover, &:active, &:focus {
										color: $link;
									}
								}
							}

							.h4 {
								color: $black;
								margin-bottom: 0;
								font-size: em(16px);

								a {
									&, &:link, &:visited {
										color: inherit;
										font-weight: 600;
									}
									&:hover, &:active, &:focus {
										color: $link;
									}
								}
							}
						}
					}
				}
	    }
	  }
	}

	section.updates {
	  align-self: center;
	  margin: 80px 5%;
	  max-width: 1360px;
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  grid-gap: 60px;

	  @media (max-width: 540px) {
	    grid-template-columns: 1fr;
	    grid-gap: 40px;
	  }

	  span.day {
	    color: $gray;
	    font-family: $headerFont;
	    margin: -20px 0 16px 0;
	    display: inline-block;
			font-size: 2.5em; // 40px;
	  	font-style: normal;
	  	font-weight: 700;
	    line-height: 1.2;

	    @media (max-width: 767px) {
	  		font-size: 1.95em; // 31px;
	  	}
	  }

	  .h3 {
	    border-bottom: 1px solid $silver;
	    padding-bottom: 10px;
	  }

	  .h4 a {
			&, &:link, &:visited {
				color: $black;
				font-weight: normal;
			}
	    &:hover, &:active, &:focus {
	      color: $link;
	    }
	  }

	  a.more {
	    &, &:link, &:visited {
				color: $teal;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				display: inline-block;
				margin: 50px 0 16px;

				@media (max-width: 540px) {
					margin-top: 36px;
				}
			}

	    &:hover, &:active, &:focus {
	      color: $linkHover;
	    }
	  }

	  .h5 {
	    color: $tan;

	    &.date {
	      color: $gray;
	    }
	  }

	  .post {
	    margin: 60px 0 0;

	    @media (max-width: 540px) {
	      margin: 36px 0 0;
	    }
	  }
	}

}
