main.bio {
	width: 100%;
	display: flex;
	margin: 100px auto 0;
	@media (max-width: 1024px) {
		margin: 84px auto 0;
	}
	@media (max-width: 1023px) {
		flex-flow: column wrap;
	}

	section.bioLeft {
		width: calc(50% - 1px);
		height: calc(100vh - 60px);
		position: sticky;
		position: -webkit-sticky;
		top: 60px;
		border-right: 1px solid $silver;

		@media (max-width: 1024px) {
			top: 0;
		}

		@media (max-width: 1023px) {
			width: 100%;
			height: calc(300px + 50vw);
			position: relative;
			border: none;
		}

		.bioPhoto {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center center;
			background-repeat: none;
			position: relative;
			overflow: hidden;

			picture {
				position: relative;
				width: 100%;
				height: 100%;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.bioConnect {
				position: absolute;
				width: 100%;
				bottom: 48px;
				display: flex;
				justify-content: center;
				align-content: stretch;
				height: 44px;

				@media (max-width: 1023px) {
					bottom: 10px;
				}

				a {
					&, &:link, &:visited {
						flex-grow: 1;
						height: 100%;
						padding: 8px 0 0;
						margin: 0;
						border: 1px solid rgba(white, .25);
						border-left: none;
						display: flex;
						justify-content: center;
						align-items: center;
						background: rgba(black, .5);
						color: white;

						span {
							color: white;
							font-size: em(14px);
							font-style: normal;
							font-weight: 400;
							text-transform: uppercase;
							letter-spacing: 1px;
							margin: 0 0 8px;

							transition: all 0.2s ease-out;
							text-shadow: 0 2px 10px rgba(black, 0.15),
													0 1px 2px rgba(black, 0.33);
							@media (max-width: 540px) {
								font-size: em(12px);
							}
						}

						svg {
							margin-right: 8px;
							transform: translateY(-5px) scale(.9);
							@media (max-width: 540px) {
								margin-right: 4px;
							}

							path {
								fill: $teal;
								transition: all 0.2s ease-out;
							}
						}
					}

					&:hover, &:active, &:focus {
						cursor: pointer;
						z-index: 2;
						background: rgba(white, 1);
						box-shadow: 0 3px 60px rgba($black,.25), 0 1px 2px rgba($black,.05);

						span {
							color: $teal;
							text-shadow: none;
						}

						svg path {
							fill: $teal;
						}
					}

				}
			}
		}
	}

	section.bioRight {
		width: calc(50% - 120px);
		padding: 0 60px;
		margin: 54px 0 100px;

		@media (max-width: 1280px) {
			padding: 0 40px;
		}

		@media (max-width: 1023px) {
			width: 90%;
			margin: 40px 5% 80px;
			padding: 0;
		}

		.bioTitle {
			max-width: 640px;

			@media (max-width: 1023px) {
				max-width: inherit;
			}

			.h2 {
				margin: 28px 0 8px;
			}
		}

		h4 {
			font-weight: 600;
		}

		figure {
			margin: 48px 0 48px 0;

	    figcaption {
	      margin: 0;
	      font-size: em(13px);
	      padding: 8px 0;
	      display: block;
	      color: $textColor;
	      border-bottom: 1px solid $silver;
	    }
	  }
	}

	blockquote {
		margin: 32px 0 48px;
		padding-left: 20px;
		border-left: 4px solid $silver;
		width: calc(100% - 64px);
		color: $teal;
		font-size: em(26px);
		line-height: 1.4;
	}
}

nav.bioAnchors {
	margin-bottom: 40px;

	p.jump {
		font-style: italic;
		font-weight: 700;
		font-family: $headerFont;
		margin-bottom: 8px;
		width: 100%;
		color: $tan;
	}

	.bioAnchorContainer {
		width: 100%;
		display: flex;

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}

		a {
			width: calc(25% - 2px);
			padding: 12px 0;
			border: 1px solid #ddd;
			color: $gray;
			transition: all 0.2s ease-out;
			text-align: center;

			&.h5 {
				margin: 0;
				font-weight: 500;
				font-size: em(11px);
			}

			@media (max-width: 1280px) {
				letter-spacing: 0;
			}

			@media (max-width: 767px) {
				width: 100%;
				padding: 12px 0;
				text-align: center;
			}

			&:hover {
				z-index: 2;
				border: 1px solid $tan;
				box-shadow: 0px 2px 32px rgba($black,.15),
										0px 1px 2px rgba($black,.05);
				cursor: pointer;

				@media (max-width: 767px) {
					border: 1px solid $tan;
					box-shadow: 0px 2px 32px rgba($black,.1),
											0px 1px 2px rgba($black,.05);
				}
			}
		}
	}
}

.bioDetails {
	width: 100%;

	.bioSection {

		h3 {
			border-bottom: 1px solid $lightsilver;
			font-size: 1.4375em;
			margin-bottom: 24px;
		}

		h4 {
			font-size: 1em;
			margin-bottom: 6px;
		}

		span {
			margin-top: -100px;
		  padding-bottom: 100px;
		  display: block;
		}

	}

}

@media (min-width: 1024px) {
	.bioRight {
		.leftArrowTeal {
			margin: 13vh 0 75vh;
		}
	}
}
