form.whitePaperForm, form.newsletterForm, form.contactForm, form.tcjaForm, form.marketingForm {
	input,
	select,
	textarea {
		padding: 10px;
		font-size: 16px;
		border: 1px solid $silver;

		&:focus {
			border: 1px solid $black;
		}
	}

	select {
		background: $lightsilver;
		padding: 10px;
	}

	input::placeholder,
	textarea::placeholder {
		color: #999;
	}

	.freeform-row .freeform-column .freeform-label {
		font-size: em(12px);
		margin-bottom: 6px;
		font-weight: 600;
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	button {
		background: none;
		border: 2px solid $teal;
		font-weight: 400;
		font-size: $mobileTextSize;
		font-family: $bodyFont;
		line-height: $mobileLineHeight;
		color: $teal;
		display: block;
		padding: 10px 24px;
		margin: 8px 0 0 0;
		min-width: 120px;
		border-radius: 40px;
		transition: all .2s ease-out;
		cursor: pointer;
		box-shadow: 0px 0px 0px 1px rgba($silver,0),
								0px 0px 0px 2px rgba(white,0);

		&:hover, &:active, &:focus {
			background: $linkHover;
			color: white;
			box-shadow: 0px 0px 0px 1px rgba($silver,.5),
									0px 0px 0px 2px rgba(white,.5);
		}
	}

	.download-btn {
		background: $teal;
		border: none;
		font-weight: 400;
		font-size: $mobileTextSize;
		font-family: $bodyFont;
		line-height: $mobileLineHeight;
		color: white;
		display: block;
		padding: 10px 24px;
		margin: 12px 0 0 0;
		min-width: 150px;
		border-radius: 40px;
		transition: all .2s ease-out;
		cursor: pointer;
		text-align: center;
    margin: 0 0 40px;
		box-shadow: 0px 0px 0px 1px rgba($silver,0),
								0px 0px 0px 2px rgba(white,0);

		&:hover, &:active, &:focus {
			background: $linkHover;
			color: white;
			box-shadow: 0px 0px 0px 1px rgba($silver,.5),
									0px 0px 0px 2px rgba(white,.5);
		}
	}

	.freeform-row .freeform-column .freeform-input[type=checkbox], .freeform-row .freeform-column .freeform-input[type=radio] {
		margin: 10px;
	}

	.freeform-instructions {
		font-size: 14px !important;
		color: $gray !important;
	}
}

.form-success {
	background: #eef9f6;
	border: 1px solid $teal;
	padding: 24px 20px 26px;
	text-align: center;
	margin-bottom: 24px;
}

.whitePaperDetails {
	form.whitePaperForm, form.marketingForm {

		.freeform-row .freeform-column .freeform-label {
			color: white;
		}

		.freeform-input {
			background: rgba(white,.05);
			border: 1px solid rgba(white,.2);
			color: white;

			&:active, &:visited, &:focus {
				background: rgba(white,.02);
				outline-color: $mint;
			}
		}

		button {
			border: 1px solid $mint;
			color: white;
		}

		.download-btn {
			box-shadow: none;
			border: none;

			&:hover, &:active, &:focus {
				box-shadow: none;
				border: none;
			}
		}
	}

	.form-success {
		background: none;
		border: 1px solid $mint;
		padding: 24px 20px 26px;
		text-align: center;
		margin-bottom: 24px;
		color: $lightMint;
	}
}

// Overrides for ActOn form embed
.ao-form {

	margin: 0 0 56px;
	padding-bottom: 56px;
	border-bottom: 1px solid $lightsilver;

	input,
	select,
	textarea {
		padding: 10px !important;
		font-size: 16px !important;
		border: 1px solid $silver !important;
		margin-top: 4px !important;
		&:focus {
			border: 1px solid $black !important;
		}
	}

	select {
		background: $lightsilver !important;
		padding: 10px !important;
	}

	input::placeholder,
	textarea::placeholder {
		color: #999 !important;
	}

	label {
		color: $black !important;
		font-size: em(12px);
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 600;
	}

	label input[value=testme] {
		color: blue;
	}

	button.ao-form-submit {
		padding: 11px 32px 12px 32px !important;
		margin-top: 12px !important;
		font-family: $bodyFont;
		font-size: 16px !important;
		transition: .2s ease !important;
	}
}


label[type="checkbox"] {
	color: blue !important;
	margin: 100px;
}
