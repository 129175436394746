main.videoList {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px auto 0 auto;

	section.videos {
		display: flex;
		flex-flow: row wrap;
		width: 90vw;
		margin: 32px auto;
		padding: 0 5vw;
		max-width: 1188px;
		justify-content: space-between;

		@media (max-width: 1024px) {
			margin: 24px 0;
		}

		span.time {
			font-family: $bodyFont;
			font-size: em(12px);
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin: 0;
			color: $textColor;
		}

		.videoCard {
			width: calc(50% - 48px);
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			padding: 24px;
			position: relative;


			@media (max-width: 1023px) {
				flex-flow: row wrap;
				width: 48%;
				padding: 0;
				margin-bottom: 20px;
			}

			@media (max-width: 620px) {
				width: 100%;
			}

			&:hover {
				z-index: 2;
				box-shadow: 0px 3px 60px rgba($black,.25),
										0px 1px 2px rgba($black,.05);
			}

			.videoThumb {
				position: relative;
				padding-bottom: 56.25%; /* 16:9 */
				padding-top: 25px;
				margin-bottom: 12px;
				height: 0;
				width: 100%;

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			.videoText {
				width: 100%;

				.h4 {
					margin: 0 0 8px;
					font-weight: 600;
					color: $black;
				}

				p {
					line-height: 1.5;
					margin-bottom: 12px;
				}

			}
		}
	}
}
