
.accordion {
  width: 100%;
  margin-bottom: 40px;
	border-top: 1px solid $silver;

  p {
    margin: 0;
  }

  &__button {
    position: relative;
    display: block;
    margin: 0px 0 0;
		border: none;
		border-top: 1px solid white;
		border-bottom: 1px solid $silver;
    padding: 18px 16px;
    text-align: left;
    color: $black;
    font-size: em(16px);
    width: 100%;
    transition: .2s;
    cursor: pointer;
		text-align: center;

		.h3 {
			margin: 0;
			font-size: em(25px);
			padding: 0;
		}

    &:hover, &:active, &:focus {
      outline: none;
			border-top: 1px solid $black;
			border-bottom: 1px solid $black;
      cursor: pointer;
			color: $teal;
      box-shadow: 0 1px 12px 0 rgba($teal, .1);

			.h3 {
				color: $teal;
			}

      &::after {
        border-top-color: $silver;
      }
    }

    &:focus {
      cursor: pointer;
    }

    &::after {
      display: inline-block;
      content: '';
			background-image: url('//klr-assets.s3.amazonaws.com/svgs/icon-down-green.svg');
			background-size: 16px 26px;
			height: 26px;
			width: 16px;
      position: absolute;
      right: 20px;
      top: calc(50% - 12px);
      transform: rotate(0deg);
    }
  }

  &__button.expanded {

      &::after {
        transform: rotate(180deg);
    }
  }

  &__section {
    max-height: 0vh;
    overflow: hidden;
    padding: 0;
    opacity: 1;

		h4, .h4 {
			font-weight: 600;
			color: $black;
			margin-top: 32px;
		}

    p {
      font-size: $textSize;
    	line-height: $lineHeight;
      margin: 0 0 22px;

    	@media (max-width: 767px) {
    		font-size: $mobileTextSize;
    		line-height: $mobileLineHeight;
    	}
    }

		table {
			margin-top: 32px;

		}
  }

  &__section.open {
    max-height: 200vh;
    overflow: auto;
    padding: 18px;
    visibility: visible;
    border-bottom: 1px solid $black;
    animation: appear .25s linear;

		@media (min-width: 1025px) {
			padding: 24px 97px;
		}
  }
  @keyframes appear {
    0% {
      opacity: 0;
      visibility: hidden;
			padding: 24px 97px;
      transform: translateY(6px);
    }
    100% {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}
