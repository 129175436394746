main.newsroomList {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px auto 0;

	section.pageTitle {
		width: 90vw;
		max-width: 900px;
		margin: 0 auto;
		padding: 0 5vw;
		text-align: center;

		h1 {
			margin-bottom: 20px;
		}
	}

	section.newsroomArticles {
		display: flex;
		flex-flow: row wrap;
		width: 90vw;
		margin: 40px auto 32px;
		padding: 0 5vw;
		max-width: 1140px;
		justify-content: space-between;

		@media (max-width: 1023px) {
			margin-bottom: 0;
		}

		.newsList {
			width: 62%;
			display: flex;
			flex-flow: column wrap;
			margin-bottom: 40px;
			padding-right: 7%;
			border-right: 1px solid $lightsilver;

			@media (max-width: 1023px) {
				width: 100%;
				padding-right: 0;
				margin-bottom: 20px;
				border: none;
			}

			.newsroomCard {
				width: 100%;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: flex-start;
				padding: 18px;
				transform: translateX(-18px);
				border-bottom: 1px dotted $silver;
				padding-bottom: 18px;

				&:nth-child(10n) {
					border-bottom: none;
				}

				@media (max-width: 767px) {
					padding: 24px 0 12px;
					transform: translateX(0);
					align-items: flex-start;
				}

				&:hover {
					z-index: 2;
					box-shadow: 0 3px 60px rgba($black,.25), 0 1px 2px rgba($black,.05);
				}

				figure {
					width: 25%;
					margin: 0;
					@media (max-width: 540px) {
						width: 36%;
					}
				}

				.newsroomCardText {
					width: 72%;
					padding-top: 4px;
					@media (max-width: 540px) {
						width: 60%;
					}

					.h4 {
						margin: 0 0 4px;

						a {
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
