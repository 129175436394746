aside.insightsAside {
  width: 24%;

  @media (max-width: 1023px) {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    margin: 40px 0;
  }

  h3 {
    margin: 0 0 12px;
    font-size: em(18px);
    line-height: 24px;
  }

  p.small {
    font-size: em(13px);
    line-height: 20px;
    margin: 0;
  }

  .newsletter {

    @media (max-width: 1023px) {
      padding: 0 20px;
      width: 50%;
    }
  }
}

.mediaInquiries {
  width: 100%;

  @media (max-width: 1023px) {
    width: 50%;
    padding: 0 20px;
    border-right: 1px solid $silver;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
  }

  .authorCard {
    border-bottom: 1px dotted $silver;
    margin-bottom: 28px;
    padding-bottom: 28px;

    @media (max-width: 1023px) {
      border: none;
      margin-bottom: 0;
      padding: 0 20px;
    }

    img {
      border-radius: 50%;
      max-width: 120px;
      margin-bottom: 8px;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    a.name {
      &, &:link, &:visited {
        display: block;
        margin-bottom: 4px;
        font-size: em(13px);
        line-height: 20px;
        margin: 0;
        font-weight: 600;
        color: $black;
      }

      &:hover, &:active, &:focus {
        color: $teal;
      }
    }

    a.contact {
      &, &:link, &:visited {
        display: block;
        font-size: em(13px);
        line-height: 20px;
        margin: 0;
      }
    }
  }
}
