section.insightsIntro {
  width: 90vw;
  max-width: 1140px;
  margin: 36px auto 12px auto;
  padding: 0 0 24px;
  border-bottom: 1px dotted $silver;

  .intro {
    max-width: 740px;

    p {
      margin: 0;
    }
  }
}
