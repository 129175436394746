
.pagination {
	margin: 36px 0 0 0;
	display: flex;
	justify-content: space-between;

	@media (max-width: 1023px) {
		border-bottom: 1px solid $silver;
		margin-top: 36px;
		padding-bottom: 40px;
	}

	.firstPage, .lastPage {
		width: 66px;

		a {
			&, &:link, &:visited {
				border: 1px solid $silver;
				border-radius: 2em;
				padding: 4px 12px;
			}
		}
	}

	.middlePages {
		align-self: center;
		width: calc(100% - 122px);
		text-align: center;

		a {
			padding: 0 4px;

			img {
				height: 12px;
			}

			&:hover, &:active, &:focus {
				img {
					opacity: .75;
				}
			}
		}
	}

	.lastPage {
		align-self: flex-end;
	}
}
