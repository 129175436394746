// Colors
$black:	#232221;
$gray: #333938;
$lightgray: #87908E;
$silver: #D8D8D8;
$lightsilver: #E3E3E3;
$teal: #068669;
$mint: #80E7C7;
$lightMint: #C3FFEC;
$tan: #9b6c4c;
$peach: #F6B283;

// Links
$link: $teal;
$linkHover: darken($link, 20%);
$textColor: $gray;

// Typography
$headerFont: "Quarto A", "Quarto B", serif;
$bodyFont: "Whitney SSm A", "Whitney SSm B", sans-serif;
$textColor: $gray;
$textSize: 1.0625em; //17px;
$lineHeight: 29px;
$mobileTextSize: 1em; //16px;
$mobileLineHeight: 26px;

// Global Styles
body {
	font-family: $bodyFont;
	color: $textColor;
	scroll-behavior: smooth;
	font-style: normal;
	font-weight: 400;
	display: flex;
	flex-flow: column wrap;
}

body.openMenu {
	overflow: hidden;
	position: fixed;
}

h2, h3, h4, h5, h6 {
  line-height: 1.2;
}

h1, .h1, .section {
  font-family: $headerFont;
  color: $black;
	font-size: 3.75em; //60px;
  line-height: 1.05;
	font-weight: 700;

	@media (max-width: 767px) {
		font-size: 3em; //48px;
	}
}

h2, .h2 {
  font-family: $headerFont;
  color: $black;
	font-size: 2.5em; //40px;
	font-weight: 700;
	margin: 0 0 16px;
	line-height: 1.2;
	transition: all .2s ease-out;

	@media (max-width: 767px) {
		font-size: 1.93em; //31px;
	}
}

h3, .h3 {
	font-family: $headerFont;
  color: $black;
	font-size: 1.25em; //20px;
	font-weight: 700;
	margin: 0 0 16px;
	line-height: 1.4;

	@media screen and (max-width: 768px) {
		font-size: 1.125em; //18px;
		padding: 12px 0 0 0;
	}
}

h4, .h4 {
	font-family: $bodyFont;
	font-size: 1.315em; //21px;
	font-weight: 600;
	margin: 0 0 16px;
	line-height: 1.4;
	transition: all .2s ease-out;

	@media (max-width: 767px) {
		font-size: 1.125em; //18px;
	}

	a {
		&, &:link, &:visited {
			color: $black;
			font-weight: 500;
		}
		&:hover, &:active, &:focus {
			color: $teal;
		}
	}
}

h5, .h5 {
	font-family: $bodyFont;
	font-size: 0.75em; //12px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 0 0 4px;
	line-height: 1.2;

	a {
		&, &:link, &:visited {
			font-weight: inherit;
		}
	}
}

h6 {

}

p {
  font-size: $textSize;
	line-height: $lineHeight;
  margin: 0 0 22px;

	@media (max-width: 767px) {
		font-size: $mobileTextSize;
		line-height: $mobileLineHeight;
	}
}

.intro p, p.intro {
	font-size: 1.375em; //22px;
	color: $black;
	line-height: 36px;
	margin-bottom: 36px;

	@media (max-width: 767px) {
		font-size: 1.25em; //20px;
		line-height: 1.625;
	}
}

ul, ol {
  font-size: $textSize;
	margin: 0 0 22px;
	padding: 0 0 0 20px;
	line-height: $lineHeight;

	@media (max-width: 767px) {
		font-size: $mobileTextSize;
		line-height: $mobileLineHeight;
	}

  li {
	  margin: 0 0 10px;

		ul {
			margin: 0;
			font-size: 1em;
		}
  }
}

a {
	&, &:link, &:visited {
		color: $link;
		text-decoration: none;
		transition: all .2s ease-out;
	}
  &:hover, &:active, &:focus {
    color: $linkHover;
		text-decoration: none;
  }
}

section.contentArea a {
	&, &:link, &:visited {
		border-bottom: 1px solid $lightsilver;
	}
	&:hover, &:active, &:focus {
		border-bottom: 1px solid $mint;
	}
}

a.button {

	&, &:link, &:visited {
		font-weight: 400;
		font-size: $mobileTextSize;
		font-family: $bodyFont;
		line-height: $mobileLineHeight;
		color: white;
		background: $link;
		padding: 10px 24px;
		min-width: 120px;
		text-align: center;
		display: inline-block;
		border-radius: 40px;
		box-shadow: 0px 0px 0px 1px rgba($silver,0),
								0px 0px 0px 2px rgba(white,0);
	}
	&:hover {
		background: darken($link, 5%);
		box-shadow: 0px 0px 0px 1px rgba($silver,.5),
								0px 0px 0px 2px rgba(white,.5);
	}

	&:active, &:focus {
		box-shadow: 0px 0px 0px 8px rgba($silver,1),
								0px 0px 0px 18px rgba(white,0);
	}
}

a.smButton {
	&, &:link, &:visited {
		font-size: em(13px);
		font-weight: 500;
		display: inline-block;
		padding: 6px 18px 7px 18px;
		border-radius: 5em;
		background: $link;
		color: white;
		margin-top: 10px;
	}
	&:hover, &:active, &:focus {
		background: $linkHover;
	}
}

a.inlineCtaButton {
	&, &:link, &:visited {
		font-weight: 400;
		font-size: $mobileTextSize;
		font-family: $bodyFont;
		line-height: $mobileLineHeight;
		color: white;
		background: $link;
		display: block;
		padding: 10px 24px;
		min-width: 120px;
		text-align: center;
		display: inline-block;
		border-radius: 40px;
		box-shadow: 0px 0px 0px 1px rgba(white,0),
								0px 0px 0px 2px rgba($silver,0);
	}
	&:hover {
		background: darken($link, 5%);
		box-shadow: 0px 0px 0px 1px rgba(white,.5),
								0px 0px 0px 2px rgba($silver,.5);
	}

	&:active, &:focus {
		box-shadow: 0px 0px 0px 8px rgba(white,1),
								0px 0px 0px 18px rgba($silver,0);
	}
}

blockquote {
	font-family: $headerFont;
	color: $teal;
	font-style: italic;
	font-weight: 700;
	font-size: em(26px);
	line-height: 1.3;
	max-width: 700px;
	margin: 0 auto 40px auto;

	@media (max-width: 1024px) {
		font-size: em(24px);
		line-height: 1.3;
		max-width: 100%;
		margin: 20px 20px 32px 20px;
		padding: 0;
	}

	span {
		font-size: $mobileTextSize;
		font-family: $bodyFont;
		line-height: $mobileLineHeight;
		color: $gray;
		font-style: normal;
		font-weight: 400;
		display: block;
		margin-top: 12px;
		font-size: em(13px);
	}
}

img {
	max-width: 100%;
}

input {
	font-family: $bodyFont;
	font-size: em(21px);
}

.dropCap::first-letter {
	font-size: em(68px);
	margin: 12px 10px 2px 0;
	font-family: $headerFont;
	float: left;
	color: $black;

	@media (max-width: 767px) {
		font-size: em(60px);
		margin: 12px 10px 10px 0;
	}
}

.textBlock {
	hr {
		border: solid 1px $teal;
		color: $teal;
		margin: 1em 0 2em;
	}
}
