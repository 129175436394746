main.landing {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0 80px;

	// Mobile layout will be served if browser doesn't support grid
	@media (min-width: 1025px) {
		margin: 100px 0 80px;
		display: grid;
		grid-template-columns: 1fr 24px 980px 24px 1fr;
		grid-template-rows: 250px repeat(auto);
		grid-template-areas:
		"banner banner banner	banner banner"
		". . 	lpIntro . ."
		". lpGrid	lpGrid lpGrid .";
	}
	@media (min-width: 1200px) {
		grid-template-columns: 1fr 24px 1140px 24px 1fr;
	}

	section.banner {
		grid-area: banner;
	  height: 250px;
	  width: 100vw;
		position: relative;
		margin: 0;

		@media (max-width: 767px) {
			height: 180px;
		}

		picture {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bannerContent {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;

			.bannerGradient {
				background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,0) 100%);
				height: 100%;
				display: flex;
				align-items: flex-end;
				padding: 0 5vw;

				@media (min-width: 1025px) {
					display: grid;
					grid-template-columns: 1fr 980px 1fr;
					align-content: end;
					padding: 0;
				}

				@media (min-width: 1200px) {
					grid-template-columns: 1fr 1140px 1fr;
				}

				@media (max-width: 767px) {
					align-items: center;
					justify-content: center;
					background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,.5) 100%);
				}

				.sectionTitle {
					grid-column: 2 / span 2;

					h1 {
						color: white;
						margin: 0 0 18px;
						text-shadow: 0 2px 10px rgba(black, 0.15),
												 0 1px 2px rgba(black, 0.33);

						@media (max-width: 767px) {
	 						margin: 0;
	 					}
					}
					.breadcrumb {
						margin: 0 0 24px;

						@media (max-width: 767px) {
							display: none;
						}

						a {
							&, &:link, &:visited {
								color: white;
								margin-right: 8px;
								font-family: $bodyFont;
								font-size: em(14px);
								font-weight: 500;
								text-transform: uppercase;
								letter-spacing: 1px;
							}

							&:hover, &:active, &:focus {
								color: $mint;
							}

							&::after {
								display: inline-block;
								content: ' ';
								background-image: url('https://di6zpoqlizfz.cloudfront.net/svgs/icon-right-white.svg');
								background-size: 12px 12px;
								height: 12px;
								width: 12px;
								margin-left: 10px;
							}

							&:last-child {
								color: $mint;

								&:hover, &:active, &:focus {
									cursor: default;
								}

								&::after {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}

	section.lpIntro {
		grid-area: lpIntro;
		margin: 50px 25% 0 0;

		@media (max-width: 1024px) {
			width: 90vw;
			margin: 40px 5vw 0 5vw;
		}
	}

	section.lpGrid {
		grid-area: lpGrid;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		margin: 0 auto;

		@media screen and (max-width: 1024px) {
			width: calc(90% + 40px);
		}

		@media screen and (max-width: 767px) {
			width: calc(90% + 24px);
		}

		a.lpCard {
			width: 100%;
			width: calc(33.333% - 48px);
			background: white;
			display: flex;
			flex-flow: column;
			padding: 24px;
			justify-content: flex-start;
			position: relative;

			transition: all 0.2s ease-out;
			box-shadow: 0px 2px 44px rgba($black, 0),
									0px 1px 3px rgba($black, 0);

			@media screen and (max-width: 1024px) {
				width: calc(33.333% - 40px);
				padding: 20px;
			}

			@media screen and (max-width: 767px) {
				width: calc(50% - 24px);
				padding: 12px;
			}

			.image {
				margin: 0;
			  position: relative;
				height: 120px;
				overflow: hidden;

				img {
					width: 100%;
					object-fit: cover;
					min-height: 120px;

				}
			}

			h3 {
				color: $black;
				transition: all .2s ease-out;
				margin: 0;
				padding: 12px 60px 0 0;
				font-family: $bodyFont;
				font-weight: 500;
				position: relative;
				display: flex;
				align-items: center;
				line-height: 1.3;

				svg {
					width: 20px;
					position: absolute;
					right: 0;
					transition: all .2s ease-out;
					fill: $silver;

					@media screen and (max-width: 768px) {
						display: none;
					}
				}
			}

			&:hover, &:active, &:focus {
				cursor: pointer;
				z-index: 2;
				box-shadow: 0px 3px 60px rgba($black,.25),
										0px 1px 2px rgba($black,.05);

				h3 {
					color: $teal;

					svg {
						fill: $teal;
					}
				}
			}

		}

		a.impactCard {

			.image {
				min-height: 200px;

				@media (max-width: 1200px) {
					width: 100%;
 					object-fit: cover;
 					min-height: 120px;
				}
			}

			p {
				color: $black;
				margin: 3px 0 6px 0;
				font-size: em(15px);
				line-height: 23px;

				@media (max-width: 767px) {
					display: none;
				}
			}
		}

	}
}
