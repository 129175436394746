section.photoFeature {
	height: 75vh;
	width: 100%;
	background-size: cover;
	background-position: center center;
	position: relative;

	@media (max-width: 767px) {
		height: 400px;
		margin-bottom: 1px;
	}

	picture {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

	.photoFeatureContent {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
	}

	.gradient {
		display: flex;
		background: linear-gradient(90deg, rgba(black,.67) 0%, rgba(black,0) 100%);
		height: 100%;

		@supports (display: grid) {
			background: linear-gradient(90deg, rgba(black,.67) 0%, rgba(black,0) 100%);
			height: 100%;
			align-self: center;
			display: grid;
			grid-template-columns: 5% 1fr 25% 1fr 5%;
		}

		@media (max-width: 767px) {
			background: linear-gradient(90deg, rgba(black,.75) 0%, rgba(black,.25) 100%);
			height: 400px;
		}
	}

	.featureText {
		margin: 10vh 0 0 6vw;
		max-width: 400px;

		@supports (display: grid) {
			grid-column: 2 / span 1;
			align-self: center;
			margin: 0;
			max-width: auto;

			@media (max-width: 768px) {
				grid-column: 2 / span 2;
			}

			@media (max-width: 540px) {
				grid-column: 2 / span 3;
			}
		}

		.h5 {
			color: $peach;
		}

		h2, p {
			color: white;
		}

		.h5, h2, p, a {
			text-shadow: 0 1px 4px rgba(black, 0.25);
		}

		h2 {
			a {
				&, &:link, &:visited {
					display: block;
					color: inherit;
					font-size: inherit;
					font-weight: inherit;
					line-height: 1.2;
				}
				&:hover, &:active, &:focus {
					color: $mint;
				}
			}
		}

		a {
			&, &:link, &:visited {
				color: $mint;
				display: inline-block;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				margin: 0 0 16px;
				line-height: 1.5;
			}

			&:hover, &:active, &:focus {
				color: $lightMint;
			}
		}
	}

	&.rightText {

		.gradient {
			display: flex;

			@supports (display: grid) {
				background: linear-gradient(-90deg, rgba(black,.7) 0%, rgba(black,0) 100%);
				height: 100%;
				align-self: center;
				display: grid;
				grid-template-columns: 5% 1fr 25% 1fr 5%;
			}

			@media (max-width: 767px) {
				background: linear-gradient(90deg, rgba(black,.75) 0%, rgba(black,.25) 100%);
				height: 400px;
			}
		}

		.featureText {
			grid-column: 4 / span 1;
			align-self: center;

			@media (max-width: 768px) {
				grid-column: 2 / span 2;
			}

			@media (max-width: 540px) {
				grid-column: 2 / span 3;
			}
		}
	}

	&.nextUp {
		height: 60vh;
		margin-top: 1px;

		@media (max-width: 1440px) {
			height: 50vh;
		}

		@media (max-width: 767px) {
			height: auto;
		}

		.gradient {
			background: linear-gradient(0deg, rgba(black,.5) 0%, rgba(black,.5) 100%);
			height: 100%;
			align-self: center;
			display: grid;
			grid-template-columns: 5% 1fr 25% 1fr 5%;

			@media (max-width: 767px) {
				padding: 0;
				height: auto;
				padding: 80px 0 60px;
			}
		}

		.featureText {
			grid-column: 2 / span 3;
			align-self: center;
			text-align: center;
			max-width: 720px;
			margin: 0 auto;

			@media (max-width: 540px) {
				grid-column: 2 / span 3;
			}
		}
	}
}
