main.eventEntry {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	margin: 80px 0 0 0;

	article.layout {
		display: grid;
		grid-template-columns: 180px 640px 180px;
		grid-column-gap: 70px;
		grid-row-gap: 40px;
		justify-content: center;
		grid-template-areas:
		"title 	title			title"
		"left 	article 	right";

		@media (max-width: 1280px) {
			grid-template-columns: 5% 140px 60px 1fr 60px 140px 5%;
			grid-column-gap: 0;
			grid-template-areas:
			". title title	title	 title title ."
			". left  .	article . right .";
		}

		@media (max-width: 920px) {
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 0;
			grid-template-areas:
			"title title"
			"left left"
			"article article";
		}

		.eventTitle {
			grid-area: title;
			text-align: center;
			width: 80%;
			padding: 0 10% 40px 10%;
			margin-top: 80px;
			border-bottom: 1px solid $silver;

			@media (max-width: 1280px) {
				width: 90%;
				padding: 0 5% 40px 5%;
			}

			@media (max-width: 1280px) {
				margin-bottom: 40px;
			}

			@media (max-width: 767px) {
				width: 86vw;
				padding: 0 7vw 40px 7vw;
			}

			span.topics {
				color: $tan;
				font-size: em(12px);
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 1px;
				margin: 0 0 4px;
			}

			h1 {
				margin: 0 0 12px;
				font-size: em(40px);
				line-height: 45px;
			}
		}

		h3 {
			margin: 0 0 2px;
			font-size: em(18px);
			line-height: 24px;
		}

		p.small {
			font-size: em(13px);
			line-height: 20px;
			margin: 0;
		}

		aside.leftAside {
			grid-area: left;

			@media (max-width: 920px) {
				align-items: center;
				text-align: center;
				align-self: center;
				padding: 8px 0 20px;
			}

			.eventMeta {
				border-bottom: 1px dotted $silver;
				margin-bottom: 28px;
				padding-bottom: 28px;

				&:last-child {
					border-bottom: none;
				}

				@media (max-width: 920px) {
					padding: 0;
					width: 88vw;
					margin: 0 6vw;
					margin-bottom: 12px;
					padding-bottom: 12px;
				}

				p {
					line-height: 26px;
				}

				&.mapLeading {
					line-height: 26px;
				}
			}
		}

		section.contentArea {
			grid-area: article;
			max-width: 1140px;
			margin: 0 auto 40px auto;

			@media (max-width: 920px) {
				width: 86vw;
				padding: 0 7vw 40px 7vw;
				border-bottom: 1px solid $silver;
				margin: 0;
			}

			figure.leadPhoto {
				margin: 0 0 32px 0;
			}

			h4 {
				font-weight: 600;
				color: $black;
			}

			.eventTable {
				width: 100%;
				margin: 36px 0;

				.eTable {
					margin: 10px 0 36px;
				}
			}

			ol,
			ul {
				padding: 0;

				li {
					margin-left: 32px;
					margin-right: 32px;
				}
			}

			a.eventButton {
				margin: 24px 0 40px 50%;
				transform: translateX(-50%);
			}
		}

		aside.rightAside {
			grid-area: right;

			@media (max-width: 920px) {
				display: none;
			}

			.newsletter {
				border-bottom: 1px dotted $silver;
				margin-bottom: 28px;
				padding-bottom: 28px;

				a.smButton {
					&, &:link, &:visited {
						font-size: em(13px);
						font-weight: 500;
						display: inline-block;
						padding: 6px 18px 7px 18px;
						border-radius: 5em;
						background: $link;
						color: white;
						margin-top: 10px;
					}

					&:hover, &:active, &:focus {
						background: $linkHover;
					}
				}
			}

			.share {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;


				h3 {
					width: 100%;
					margin-bottom: 10px;
				}
				button {
					margin-right: 10px;
				}
			}
		}
	}
}
