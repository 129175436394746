#searchOverlay {

}

.overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 6;

  opacity: 1;

  top: 0;
  left: 0;
  background-color: rgba(white, .95);
}

.fadein {
  animation: fadein .2s linear;
}

.appear {
  display: inherit;
}

@keyframes fadein {
  0% {
    opacity: 0;
    /* Changing visibility breaks keyboard trap for modals. Do not uncomment */
    //visibility: hidden;
  }
  100% {
    opacity: 1;
    //visibility: visible;
  }
}

.closebtn {
  position: absolute;
  top: 24px;
  right: 4.5%;
  background: white;
  width: 30px;
  height: 30px;
  padding: 8px;
  border-radius: 2em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .1s ease-out;
  box-shadow: 0 1px 2px 0 rgba($black, .44);
  box-sizing: content-box;
  outline: none;
  border: 0px;

  img {
    width: 14px;
    height: 14px;
    pointer-events: none;
  }

  &:hover, &:active, &:focus {
    transform: scale(1.025);
  }
  &:active, &:focus {
    outline: rgb(94, 158, 215) auto 5px;
  }
}

.overlayContent {
  position: absolute;
  top: 50%;
  width: 90%;
  left: 5%;
  transform: translateY(-50%);

  @media (min-width: 768px) {
    width: 60%;
    left: 20%;
  }
}

.fadeup {
  animation: fadeup .4s linear;
}

@keyframes fadeup {
  0% {
    opacity: 0;
    /* Changing visibility breaks keyboard trap for modals. Do not uncomment */
    //visibility: hidden;
    transform: translateY(0%);
  }
  50% {
    opacity: 0;
    //visibility: hidden;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    //visibility: visible;
    transform: translateY(-50%);
  }
}

form#search {
  display: flex;
  justify-content: center;
  position: relative;

  input[type=text] {
    padding: 20px 30px 20px 66px;
    border: none;
    width: 100%;
    background: white;
    transition: all .1s ease-out;
    border-radius: 2em;
    box-shadow: 0 1px 3px 0 rgba($black, .2),
                0 3px 18px 0 rgba($teal, .2);

    &::placeholder {
      color: $lightgray;
    }
    &:focus {
      outline: none;
      box-shadow: 0 1px 2px 0 rgba($black, .5),
                  0 4px 24px 0 rgba($teal, .3);
    }
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 19px;
  }
}

// Search results
ul.search-results {
  margin: 0;
  padding: 0;

  li {
    border-bottom: 1px solid $silver;
    list-style: none;
    padding: 10px 4px 20px 4px;

    a {

      h4 {
        margin-bottom: 6px;
        font-weight: 500 !important;
      }

      p {
        color: $textColor;
      	font-size: em(14px);
        line-height: 1.5;
      	margin: 0;
      }

      &:hover, &:active, &:focus {
        h4, p {
          color: $teal;
        }
      }
    }
  }
}
